import { gql } from '@apollo/client';

export const FETCH_TENANTS = gql`
  query fetchTenants($filter: TenantFilter!, $where: TenantsWhereInput) {
    tenants(filter: $filter, where: $where) {
      count
      data {
        id
        name
        email
        isActive
        helpPage {
          key
          checked
        }
        liveChat {
          key
          checked
        }
        subDomain
        phoneNo
        sponsors {
          businessName
        }
        userInfo {
          firstName
          lastName
          email
          phoneNo
        }
        location {
          addressLine1
          addressLine2
          addressLine3
          city
          state
          country
          zipCode
        }
      }
    }
  }
`;

export const TENANT_FILTER = gql`
  query tenantFilters($filter: TenantFilter!) {
    tenantFilters(filter: $filter) {
      count
      data {
        name
        subDomain
        phoneNo
        email
        isActive
      }
    }
  }
`;

export const SPONSORS_FILTER = gql`
  query sponsors($filter: SponsorFilter!) {
    sponsors(filter: $filter) {
      count
      data {
        id
        businessName
      }
    }
  }
`;

export const FETCH_TENANT = gql`
  query singleTenant($id: ID!) {
    tenant(where: { id: $id }) {
      id
      name
      email
      isActive
      helpPage {
        key
        checked
      }
      liveChat {
        key
        checked
      }
      subDomain
      phoneNo
      sponsors {
        id
        firstName
        lastName
        businessName
        email
        subDomain
        phoneNo
        status
        pickListStatus {
          label
        }
      }
      userInfo {
        firstName
        lastName
        email
        phoneNo
      }
      location {
        addressLine1
        addressLine2
        addressLine3
        city
        state
        country
        zipCode
      }
    }
  }
`;

export const FETCH_TENANT_BRANDS = gql`
  query brands($filter: BrandFilter!, $where: BrandsWhereFilter) {
    brands(filter: $filter, where: $where) {
      count
      data {
        id
        name
        logo {
          url
        }
        createdAt
        isActive
      }
    }
  }
`;

export const FETCH_TENANT_LINE_OF_BUSINESS = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        subAreas {
          label
          key
        }
        isActive
        description
      }
    }
  }
`;

export const GET_LINE_OF_BUSINESS = gql`
  query lineOfBusiness($id: ID!) {
    lineOfBusiness(where: { id: $id }) {
      id
      label
      lobCode
      key
      description
      isActive
      tenantId
      referenceId
    }
  }
`;

export const FETCH_TENANT_SUB_AREA = gql`
  query subAreas($filter: SubAreaFilter!, $where: SubAreasWhereFilter) {
    subAreas(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        isActive
      }
    }
  }
`;

export const FETCH_TENANT_USERS = gql`
  query users($filter: UserFilter!, $where: UsersWhereFilter) {
    users(filter: $filter, where: $where) {
      count
      data {
        id
        firstName
        lastName
        email
        phoneNo
        roles
        roleValues {
          id
          key
          label
        }
        isActive
      }
    }
  }
`;

export const FETCH_TENANT_SUBSCRIPTION = gql`
  query tenantSubscriptionPlans($filter: TenantSubscriptionPlanFilter!) {
    tenantSubscriptionPlans(filter: $filter) {
      count
      tenantPlans {
        id
        isActive
        subscriptionPlanId
        tenantId
        subscriptionPlan
      }
    }
  }
`;

export const BRAND_FILTER = gql`
  query brandFilters($filter: BrandFilter!) {
    brandFilters(filter: $filter) {
      count
      data {
        name
        refData
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
        secondaryColors
        tertiaryColors
        colorPallete
        description
        waiverText
        priceGuarantee
        offerText
        productDetails
        isActive
        tenantId
        createdAt
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const FETCH_STATE_CITY = gql`
  query fetchStatesAndCity($filter: USLocationTypeFilter) {
    getLocationType(filter: $filter) {
      data {
        id
        name
        state
      }
    }
  }
`;
