import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import withRouter from '../../../components/withRouter';
import useRouter from '../../../hooks/useRouter';
import SponsorDetails from '../components/SponsorDetails';
import SponsorTabs from '../components/SponsorTabs';
import { GET_SPONSOR } from '../graphql/Queries';
import '../sponsors.less';

const SponsorView = () => {
  const { params: { id } = {}, navigate } = useRouter();
  const [loading, setLoading] = useState(true);

  const { data: { sponsor } = {} } = useQuery(GET_SPONSOR, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    },
  });

  return (
    <Card className="full-height-card card-body-p-24 sponsor-management">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.SPONSORS} />
          {sponsor && (
            <span className="portal-header">
              {`${sponsor?.firstName} ${sponsor?.lastName}`}
            </span>
          )}
        </div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          type="primary"
          onClick={() => navigate(`${ROUTES?.SPONSORS}/edit/${id}`)}
        >
          Edit
        </Button>
      </Portal>
      <SponsorTabs>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <SponsorDetails sponsorData={sponsor} />
        )}
      </SponsorTabs>
    </Card>
  );
};
export default withRouter(SponsorView);
