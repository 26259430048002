import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo-with-text.png';
import {
  RESEND_OTP_ACCOUNT_MINUTES,
  RESEND_OTP_TIME,
  ROUTES,
  USER_EMAIL,
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import './auth.less';
import { LOGIN } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const getUserCred = () =>
  // eslint-disable-next-line no-undef
  localStorage?.getItem(USER_EMAIL) || null;

const Login = () => {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {}, // Always write this method for error handling in all mutation.
  });
  const userEmail = getUserCred();

  useEffect(() => {
    if (userEmail) {
      form?.setFieldsValue({
        email: userEmail,
      });
    }
  }, [userEmail]);

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
      };

      const response = await loginMutate({
        variables: { data: { ...formValues } },
      });
      if (response?.data) {
        // eslint-disable-next-line no-undef
        localStorage.setItem(
          USER_EMAIL,
          values?.email?.trim().toLowerCase() ?? null,
        );
        // eslint-disable-next-line no-undef
        localStorage.setItem(
          RESEND_OTP_TIME,
          dayjs(
            dayjs()?.add(RESEND_OTP_ACCOUNT_MINUTES, 'minutes'),
          )?.valueOf() ?? null,
        );

        navigate(ROUTES?.OTP_VERIFICATION);
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="auth-bg">
      <div className="gx-login-container">
        <div className="login-wrap d-flex align-center justify-start">
          <Card className="full-width login-card">
            <Spin spinning={loginLoading} wrapperClassName="full-width">
              <div className="gx-login-header gx-text-center fill-width mb-24 mt-24">
                <img src={Logo} width={287} alt="Path Pro" />
              </div>
              <Form
                name="Login"
                onFinish={onFinish}
                size="large"
                form={form}
                className="gx-login-form gx-form-row0"
              >
                <Form.Item
                  name="email"
                  rules={[{ required, message: 'Please enter email!' }, email]}
                >
                  <Input prefix={<UserOutlined />} placeholder="Enter email" />
                </Form.Item>

                <Form.Item
                  name="password"
                  className="mb-8"
                  rules={[{ required, message: 'Please enter password!' }]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Enter password"
                  />
                </Form.Item>
                <Form.Item className="full-width mb-8">
                  <Button
                    type="primary"
                    className="full-width"
                    htmlType="submit"
                  >
                    Login
                  </Button>
                </Form.Item>
                <Form.Item className="text-center mb-8">
                  <Link to={ROUTES?.FORGET_PASSWORD}>Forgot password ?</Link>
                </Form.Item>
              </Form>
            </Spin>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;
