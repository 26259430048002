import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formatPhoneNumberWithoutMask } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';

import withRouter from '../../../components/withRouter';
import UserForm from '../components/UserForm';
import UserTabs from '../components/UserTabs';
import { CREATE_USER } from '../graphql/Mutations';

function UserCreate() {
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [createUserMutate] = useMutation(CREATE_USER, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const locations = [];
    if (
      formValues?.addressLine1 ||
      formValues?.city ||
      formValues?.state ||
      formValues?.country ||
      formValues?.zipCode
    ) {
      locations?.push({
        addressLine1: formValues?.addressLine1 || null,
        city: formValues?.city || null,
        state: formValues?.state || null,
        country: formValues?.country || null,
        zipCode: formValues?.zipCode || null,
      });
    }

    const newFormValues = {
      ...formValues,
      phoneNo: formatPhoneNumberWithoutMask(formValues?.phoneNo)?.slice(-10),
      locations,
    };
    delete newFormValues?.addressLine1;
    delete newFormValues?.city;
    delete newFormValues?.state;
    delete newFormValues?.country;
    delete newFormValues?.zipCode;

    const variables = newFormValues;

    try {
      const response = await createUserMutate({
        variables: { data: { ...variables } },
      });
      if (response?.data?.adminCreateUser) {
        navigate(ROUTES?.USERS);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    country: 'USA',
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.USERS} />
          <span className="portal-header">Add User</span>
        </div>
      </Portal>
      <UserTabs>
        <UserForm
          userData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </UserTabs>
    </Card>
  );
}

export default withRouter(UserCreate);
