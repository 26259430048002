import { Button, Card } from 'antd';
import React from 'react';
import { ROUTES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import useRouter from '../../../hooks/useRouter';
import TenantTable from '../components/TenantTable';

const TenantList = () => {
  const { navigate } = useRouter();
  const handleAdd = () => {
    navigate(`${ROUTES?.TENANTS}/add`);
  };
  return (
    <Card className="full-height-card card-body-p-20">
      <Portal portalId="header-left-content">
        <span className="portal-header">Tenants</span>
      </Portal>
      <Portal portalId="header-right-content">
        <Button type="primary" onClick={handleAdd}>
          Add Tenant
        </Button>
      </Portal>
      <TenantTable />
    </Card>
  );
};
export default TenantList;
