import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import withRouter from '../../../components/withRouter';
import useRouter from '../../../hooks/useRouter';
import TenantDetails from '../components/TenantDetails';
import CardWrapper from '../components/TenantTabs';
import { FETCH_TENANT } from '../graphql/Queries';

export const TenantView = () => {
  const { params: { id } = {}, navigate } = useRouter();
  const [loading, setLoading] = useState(true);

  const { data } = useQuery(FETCH_TENANT, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    },
  });

  if (!id) {
    <Navigate to={ROUTES?.TENANTS} replace />;
  }

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.TENANTS} />
          <span className="portal-header">{data?.tenant?.name}</span>
        </div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          type="primary"
          onClick={() => navigate(`${ROUTES?.TENANTS}/edit/${id}`)}
        >
          Edit
        </Button>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <TenantDetails tenantData={data?.tenant} />
        )}
      </CardWrapper>
    </Card>
  );
};

export default withRouter(TenantView);
