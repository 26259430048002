import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_PLAN = gql`
  mutation updateSubscriptionPlan(
    $where: UniqueWhereInput!
    $data: SubscriptionPlanInput!
  ) {
    updateSubscriptionPlan(where: $where, data: $data) {
      message
      status
    }
  }
`;
