import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Space } from 'antd';
import React from 'react';
import { formValidatorRules } from '../../../common/utils';
import { CHANGE_PASSWORD } from '../graphql/Mutations';

const { required } = formValidatorRules;

const ChangePasswordModal = ({ visible, setVisible }) => {
  const [changePasswordMutate, { loading }] = useMutation(CHANGE_PASSWORD, {
    onCompleted() {
      setVisible(false);
    },
    onError() {},
  });

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = async ({ oldPassword, newPassword }) => {
    await changePasswordMutate({
      variables: {
        data: {
          oldPassword,
          newPassword,
        },
      },
    });
  };
  return (
    <Modal
      title="Change Password"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="change-password"
        onFinish={onFinish}
        className="gx-login-form gx-form-row0"
      >
        <Form.Item
          name="oldPassword"
          rules={[required]}
          normalize={(value) => value?.split(' ')?.join('')}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Old Password"
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          rules={[required]}
          normalize={(value) => value?.split(' ')?.join('')}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          normalize={(value) => value?.split(' ')?.join('')}
          rules={[
            required,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || value !== getFieldValue('newPassword')) {
                  return Promise?.reject(new Error('Passwords do not match'));
                }
                return Promise?.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Confirm password"
          />
        </Form.Item>
        <div className="d-flex">
          <Space>
            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                className="mr-2"
                htmlType="submit"
              >
                Change Password
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
