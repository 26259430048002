import { CloseCircleOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import keysDown from './components/Hotkeys/HotKeys';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import ApiLogList from './modules/apiLogs/pages/ApiLogList';
import ApiLogView from './modules/apiLogs/pages/ApiLogView';
import VerifyEmail from './modules/auth/EmailVerify';
import ForgetPassword from './modules/auth/ForgetPassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import OtpVerification from './modules/auth/OtpVerification';
import ResetPassword from './modules/auth/ResetPassword';
import ImportExportQueueList from './modules/importExportQueue/pages/ImportExportQueueList';
import LineOfBusinessesTableList from './modules/industries/lineOfBusinesses/pages/LineOfBusinessesTableList';
import SubAreaTableList from './modules/industries/lineOfBusinesses/pages/SubAreaTableList';
import IndustryList from './modules/industries/pages/IndustryList';
import PlanEdit from './modules/plans/pages/PlanEdit';
import PlansList from './modules/plans/pages/PlanList';
import Profile from './modules/profile/Profile';
import SponsorCreate from './modules/sponsors/pages/SponsorCreate';
import SponsorEdit from './modules/sponsors/pages/SponsorEdit';
import SponsorList from './modules/sponsors/pages/SponsorList';
import SponsorView from './modules/sponsors/pages/SponsorView';
import BrandList from './modules/tenant/pages/BrandList';
import LineOfBusinessList from './modules/tenant/pages/LineOfBusinessList';
import PlanList from './modules/tenant/pages/PlanList';
import SubAreaList from './modules/tenant/pages/SubAreaList';
import AddEditTenant from './modules/tenant/pages/TenantCreate';
import TenantEdit from './modules/tenant/pages/TenantEdit';
import TenantList from './modules/tenant/pages/TenantList';
import { TenantView } from './modules/tenant/pages/TenantView';
import UsersList from './modules/tenant/pages/UsersList';
import UserCreate from './modules/users/pages/UserCreate';
import UserEdit from './modules/users/pages/UserEdit';
import UserList from './modules/users/pages/UserList';
import UserView from './modules/users/pages/UserView';

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const AUTH_MODULES = [
    {
      path: ROUTES?.LOGIN,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [{ path: ROUTES?.LOGIN, element: <Login /> }],
    },
    {
      path: ROUTES?.OTP_VERIFICATION,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [
        { path: ROUTES?.OTP_VERIFICATION, element: <OtpVerification /> },
      ],
    },
    {
      path: ROUTES?.FORGET_PASSWORD,
      element: <PublicRoute />,
      children: [
        { path: ROUTES?.FORGET_PASSWORD, element: <ForgetPassword /> },
      ],
    },
    {
      path: ROUTES?.VERIFY_EMAIL,
      children: [{ path: ROUTES?.VERIFY_EMAIL, element: <VerifyEmail /> }],
    },
    {
      path: ROUTES?.VERIFY,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.VERIFY, element: <ResetPassword /> }],
    },
    {
      path: ROUTES?.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.LOGOUT, element: <Logout /> }],
    },
  ];

  const DASHBOARD_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [{ path: ROUTES?.MAIN, element: <UserList /> }],
        },
      ],
    },
  ];

  const USER_MANAGEMENT_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: ROUTES?.USERS, element: <UserList /> },
            { path: ROUTES?.ADD_USERS, element: <UserCreate /> },
            { path: ROUTES?.EDIT_USERS, element: <UserEdit /> },
            { path: ROUTES?.VIEW_USERS, element: <UserView /> },
          ],
        },
      ],
    },
  ];

  const TENANT_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: ROUTES?.TENANTS, element: <TenantList /> },
            { path: ROUTES?.ADD_TENANT, element: <AddEditTenant /> },
            { path: ROUTES?.EDIT_TENANT, element: <TenantEdit /> },
            { path: ROUTES?.VIEW_TENANT, element: <TenantView /> },
            {
              path: `${ROUTES?.TENANTS}/view/:id${ROUTES?.BRANDS}`,
              element: <BrandList />,
            },
            {
              path: `${ROUTES?.TENANTS}/view/:id${ROUTES?.LINE_OF_BUSINESSES}`,
              element: <LineOfBusinessList />,
            },
            {
              path: `${ROUTES?.TENANTS}/view/:id${ROUTES?.USERS}`,
              element: <UsersList />,
            },
            {
              path: `${ROUTES?.TENANTS}/view/:id${ROUTES?.LINE_OF_BUSINESSES}/:lobId${ROUTES?.SUB_AREAS}`,
              element: <SubAreaList />,
            },
            {
              path: `${ROUTES?.TENANTS}/view/:id${ROUTES?.PLANS}`,
              element: <PlanList />,
            },
          ],
        },
      ],
    },
  ];

  const SPONSOR_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: ROUTES?.SPONSORS, element: <SponsorList /> },
            { path: `${ROUTES?.SPONSORS}/add`, element: <SponsorCreate /> },
            { path: `${ROUTES?.SPONSORS}/edit/:id`, element: <SponsorEdit /> },
            { path: `${ROUTES?.SPONSORS}/view/:id`, element: <SponsorView /> },
          ],
        },
      ],
    },
  ];

  const INDUSTRY_MODULE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.INDUSTRIES,
              element: <IndustryList />,
            },
            {
              path: `${ROUTES?.INDUSTRIES}/:industryId${ROUTES?.LINE_OF_BUSINESSES}`,
              element: <LineOfBusinessesTableList />,
            },
            {
              path: `${ROUTES?.INDUSTRIES}/:industryId${ROUTES?.LINE_OF_BUSINESSES}/:lobId${ROUTES?.SUB_AREAS}`,
              element: <SubAreaTableList />,
            },
            // commented as no need now
            // {
            //   path: `${ROUTES?.INDUSTRIES}/edit/:id`,
            //   element: <IndustryEdit />,
            // },
            // {
            //   path: `${ROUTES?.INDUSTRIES}/edit/:id/edit/:lobId/edit/:subId`,
            //   element: <SubAreaEdit />,
            // },
            // {
            //   path: `${ROUTES?.INDUSTRIES}/edit/:id/edit/:lobId`,
            //   element: <LineOfBusinessEdit />,
            // },
            // {
            //   path: `${ROUTES?.INDUSTRIES}/view/:id/view/:lobId`,
            //   element: <LineOfBusinessView />,
            // },
          ],
        },
      ],
    },
  ];

  const IMPORT_EXPORT_QUEUE_MODULE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.IMPORT_EXPORT_QUEUE,
              element: <ImportExportQueueList />,
            },
          ],
        },
      ],
    },
  ];

  const PLAN_MODULE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.PLANS,
              element: <PlansList />,
            },
            {
              path: `${ROUTES?.PLANS}/edit/:id`,
              element: <PlanEdit />,
            },
          ],
        },
      ],
    },
  ];

  const API_LOG_MODULE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.API_LOGS,
              element: <ApiLogList />,
            },
            {
              path: `${ROUTES?.API_LOGS}/view/:id`,
              element: <ApiLogView />,
            },
          ],
        },
      ],
    },
  ];

  const USER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.PROFILE,
              element: <Profile />,
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...AUTH_MODULES,
    ...DASHBOARD_MODULES,
    ...USER_MODULES,
    ...USER_MANAGEMENT_MODULES,
    ...TENANT_MODULES,
    ...SPONSOR_MODULES,
    ...INDUSTRY_MODULE,
    ...IMPORT_EXPORT_QUEUE_MODULE,
    ...PLAN_MODULE,
    ...API_LOG_MODULE,
    ...OTHER_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const { defaultPageSize, initializeAuth } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initializeAuth();
    defaultPageSize();
    setLoading(false);

    // eslint-disable-next-line no-undef
    document.addEventListener('keydown', keysDown);
    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('keydown', keysDown);
    };

    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;
  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
