import React from 'react';
import { PatternFormat } from 'react-number-format';
import './styles/CustomPhoneInput.less';

const CustomInput = React.forwardRef(({ onChange, value, ...rest }, ref) => (
  <span className="phone-input-affix-wrapper">
    <input onChange={onChange} value={value} ref={ref} type="text" {...rest} />
  </span>
));

const CustomPhoneInput = (props) => (
  <PatternFormat
    placeholder="(999) 999-9999"
    format="(###) ###-####"
    mask="_"
    customInput={CustomInput}
    {...props}
  />
);

export default CustomPhoneInput;
