import React from 'react';
import { NumericFormat } from 'react-number-format';
// import './styles/InputComponent.less';

const PriceComponent = (props) => {
  const {
    value,
    prefix = '$',
    className = '',
    onChange,
    isPrice = true,
    showPrefix = true,
    decimalScale = true,
    ...rest
  } = props;

  const handleChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue?.floatValue);
    }
  };
  return (
    <NumericFormat
      onValueChange={handleChange}
      className={`ant-input-affix-wrapper input-component price-component ${className}`}
      thousandSeparator={isPrice}
      prefix={showPrefix ? prefix : ''}
      placeholder={`${showPrefix && prefix}0.00`}
      decimalScale={decimalScale && 2}
      fixedDecimalScale
      value={value}
      {...rest}
    />
  );
};

export default PriceComponent;
