import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import documentSvg from '../../../assets/svg/document.svg';
import infoSvg from '../../../assets/svg/info.svg';
import { ROUTES } from '../../../common/constants';
import withRouter from '../../../components/withRouter';
import useRouter from '../../../hooks/useRouter';

const TenantTabs = ({ children }) => {
  const location = useLocation();

  const [activeKey, setActiveKey] = useState('BRANDS');
  const { params: { id } = {}, navigate } = useRouter();

  const navItems = [
    {
      label: 'Information',
      key: 'INFORMATION',
      children: { ...children },
      icon: <img src={infoSvg} alt="info-icon" />,
    },
    ...(location?.pathname?.includes('view')
      ? [
          {
            label: 'Retailers',
            key: 'RETAILERS',
            children: { ...children },
            icon: <img src={infoSvg} alt="info-icon" />,
          },
          {
            label: 'Lines of Business',
            key: 'LINE_OF_BUSINESSES',
            children: { ...children },
            icon: <img src={documentSvg} alt="info-icon" />,
          },
          {
            label: 'Users',
            key: 'USERS',
            children: { ...children },
            icon: <img src={documentSvg} alt="info-icon" />,
          },
          {
            label: 'Plan history',
            key: 'PLANS',
            children: { ...children },
            icon: <img src={documentSvg} alt="info-icon" />,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (
      location?.pathname?.includes('edit') ||
      location?.pathname?.includes('view') ||
      location?.pathname?.includes('add')
    ) {
      setActiveKey('INFORMATION');
    }

    if (location?.pathname?.includes('retailers')) {
      setActiveKey('RETAILERS');
    }
    if (location?.pathname?.includes('line-of-business')) {
      setActiveKey('LINE_OF_BUSINESSES');
    }
    if (location?.pathname?.includes('users')) {
      setActiveKey('USERS');
    }
    if (location?.pathname?.includes('plans')) {
      setActiveKey('PLANS');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectTab = (key) => {
    setActiveKey(key);
    switch (key) {
      case 'INFORMATION':
        if (id) {
          if (location?.pathname?.includes('edit')) {
            navigate(`${ROUTES?.TENANTS}/edit/${id}`);
          } else {
            navigate(`${ROUTES?.TENANTS}/view/${id}`);
          }
        } else {
          navigate(`${ROUTES?.TENANTS}/add`);
        }
        break;

      case 'RETAILERS':
        navigate(`${ROUTES?.TENANTS}/view/${id}${ROUTES?.BRANDS}`);
        break;

      case 'LINE_OF_BUSINESSES':
        navigate(`${ROUTES?.TENANTS}/view/${id}${ROUTES?.LINE_OF_BUSINESSES}`);
        break;

      case 'USERS':
        navigate(`${ROUTES?.TENANTS}/view/${id}${ROUTES?.USERS}`);
        break;

      case 'PLANS':
        navigate(`${ROUTES?.TENANTS}/view/${id}${ROUTES?.PLANS}`);
        break;

      default:
        break;
    }
  };

  return (
    <div className="card-container">
      <Tabs
        type="card"
        activeKey={activeKey}
        onChange={handleSelectTab}
        items={navItems}
      />
    </div>
  );
};

export default withRouter(TenantTabs);
