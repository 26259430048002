import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import { VERIFY_TOKEN } from './graphql/Mutations';

const VerifyEmail = () => {
  const {
    getToken,
    initializeAuth,
    state: { currentUser },
  } = useContext(AppContext);

  const {
    navigate,
    location: { search },
  } = useRouter();
  const params = new URLSearchParams(search);
  const token = params?.get('auth_token');
  const id = params?.get('uid');

  const [tokenValidationMutate] = useMutation(VERIFY_TOKEN, {
    async onCompleted() {
      const accessToken = getToken();
      if (accessToken) {
        await initializeAuth(accessToken, {
          ...currentUser,
          emailVerified: true,
        });
      }
      navigate(ROUTES?.MAIN, { replace: true });
    },
    onError() {
      navigate(ROUTES?.MAIN, { replace: true });
    },
  });

  useEffect(() => {
    if (token && id) {
      tokenValidationMutate({
        variables: {
          data: {
            id,
            token,
            emailVerification: true,
          },
        },
      });
    } else {
      navigate(ROUTES?.MAIN, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoaderComponent />;
};

export default VerifyEmail;
