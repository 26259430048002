import { MoreOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Menu,
  Popover,
  Tag,
  message,
} from 'antd';
import { debounce, filter, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import FilterSelectedIcon from '../../../assets/svg/filter-selected.svg';
import FilterIcon from '../../../assets/svg/filter.svg';
import api from '../../../common/api';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import {
  formatPhoneNumber,
  formatPhoneNumberWithoutMask,
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import useRouter from '../../../hooks/useRouter';
import ImportModal from '../../imports/components/ImportModal';
import { ADMIN_PICK_LIST_FILTER, GET_SPONSORS } from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1,
};

let scrollDebounce = null;

const SponsorTable = () => {
  const {
    state: { pageSize, filterData, currentSponsor },
    dispatch,
  } = useContext(AppContext);

  const initialSponsorFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const { navigate } = useRouter();

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [sortedInfo, setSortedInfo] = useState({});
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [sponsorFilter, setSponsorFilter] = useState(initialSponsorFilter);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [fetchSponsorData, { loading, data }] = useLazyQuery(GET_SPONSORS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.sponsors?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  const [adminPickListFilters] = useLazyQuery(ADMIN_PICK_LIST_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.adminPickListFilters?.data, (item) =>
          optionsCopy?.push(item),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.adminPickListFilters?.data, (item) =>
          optionsCopy?.push(item),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.adminPickListFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
  });

  useEffect(() => {
    if (pageSize) {
      setSponsorFilter({ ...sponsorFilter, limit: pageSize });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  useEffect(() => {
    if (sponsorFilter?.limit) {
      fetchSponsorData({
        variables: {
          filter: sponsorFilter,
          ...(filterData && { where: filterData }),
        },
      });
    }

    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorFilter?.limit]);

  const getFilterData = (confirm) => {
    fetchSponsorData({
      variables: {
        filter: { ...sponsorFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setFilters(filtersCopyState);
    setSponsorFilter({
      ...sponsorFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'status':
          adminPickListFilters({
            variables: {
              filter: {
                skip: 0,
                limit: 20,
                search: filterSearch,
                key: 'STATUS',
              },
            },
          });
          break;
        default:
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);

        switch (dataIndex) {
          case 'status':
            adminPickListFilters({
              variables: {
                filter: {
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  key: 'STATUS',
                },
              },
            });
            break;
          default:
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };
    setFilters(filtersCopy);
    fetchSponsorData({
      variables: {
        filter: {
          ...sponsorFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC',
        },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });
    setSponsorFilter({
      ...sponsorFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'status':
        adminPickListFilters({
          variables: {
            filter: {
              skip: 0,
              limit: 20,
              search: value,
              key: 'STATUS',
            },
          },
        });
        break;
      default:
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          <SearchComponent
            id="search-container-id-roles"
            placeholder="Search..."
            name={dataIndex}
            getData={(value) => handleSearch(value, dataIndex)}
          />
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => (
                <Tag
                  key={item}
                  closable
                  onClose={() => handleDeleteFilter(item, dataIndex)}
                  className="filter-tag"
                >
                  <span title={item?.toString()}>{item?.toString()}</span>
                </Tag>
              ))}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => (
                <div className="single-checkbox-div" key={item?.key || item}>
                  <Checkbox
                    value={item?.key || item}
                    checked={filtersCopyState?.[dataIndex]?.includes(
                      item?.key || item,
                    )}
                    key={item?.key || item}
                    onChange={(e) => changeFilter(e, dataIndex)}
                    className="single-checkbox"
                  >
                    <span title={item?.label || item?.toString()}>
                      {item?.label || item?.toString()}
                    </span>
                  </Checkbox>
                </div>
              ))
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters && filters[dataIndex]?.length > 0 ? (
        <img src={FilterSelectedIcon} alt="filter-icon" width={16} />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const handleAddEditSponsor = (editSponsorData) => {
    if (editSponsorData?.id) {
      dispatch({ type: 'SET_FILTER_DATA', data: filters });
      navigate(`${ROUTES?.SPONSORS}/edit/${editSponsorData?.id}`);
    } else {
      navigate(`${ROUTES?.SPONSORS}/add`);
    }
  };

  const handleTableChange = (pagination, _, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setSponsorFilter({ ...sponsorFilter, skip });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setSponsorFilter({
        ...sponsorFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchSponsorData({
        variables: {
          filter: {
            ...sponsorFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setSponsorFilter({
        ...sponsorFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      fetchSponsorData({
        variables: {
          filter: {
            ...sponsorFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const renderActionButtons = (editSponsorData) => (
    <div className="d-flex flex-vertical">
      <Button
        id="sponsor-table-edit-btn"
        className="b-0"
        onClick={() => {
          handleAddEditSponsor(editSponsorData);
        }}
      >
        Edit
      </Button>
      <Button
        id="sponsor-table-edit-btn"
        className="b-0"
        onClick={() => {
          navigate(`${ROUTES?.SPONSORS}/view/${editSponsorData?.id}`);
        }}
      >
        View
      </Button>
    </div>
  );

  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 130,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (text, record) => `${record?.firstName} ${record?.lastName}`,
    },
    {
      title: 'EMAIL',
      ellipsis: true,
      dataIndex: 'email',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      key: 'email',
      render: (email) => <span title={email}>{email || '-'} </span>,
    },
    {
      title: 'PHONE NUMBER',
      width: 180,
      ellipsis: true,
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
      render: (phone) =>
        formatPhoneNumber(formatPhoneNumberWithoutMask(phone)?.slice(-10)) ||
        '-',
    },
    {
      title: 'BUSINESS NAME',
      ellipsis: true,
      dataIndex: 'businessName',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'businessName' && sortedInfo?.order,
      key: 'businessName',
      render: (businessName) => (
        <span title={businessName}>{businessName || '-'} </span>
      ),
    },
    {
      title: 'STATUS',
      width: 120,
      dataIndex: 'status',
      key: 'status',
      ...filterPopup('status'),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      render: (_, record) => record?.pickListStatus?.label || '-',
    },
    {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => (
        <Popover
          placement="bottom"
          overlayClassName="action-button"
          content={renderActionButtons(record)}
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  const onSearchChange = async (value) => {
    setSponsorFilter({
      ...sponsorFilter,
      skip: value ? 0 : sponsorFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    fetchSponsorData({
      variables: {
        filter: {
          ...sponsorFilter,
          skip: value
            ? 0
            : sponsorFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        ...(filters && { where: filters }),
      },
    });
  };

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const handleExport = (e) => {
    const { key } = e;
    if (key === 'SELECTED' && !selectedKeys?.length) {
      message?.destroy();
      message?.error('Please select records!');
      return;
    }
    setExportLoading(true);
    api({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_REST_URL}/export`,
      data: {
        module: 'SPONSOR',
        sponsorId: currentSponsor?.id,
        filtersObj: {
          ...filters,
          id: key === 'SELECTED' ? selectedKeys : undefined,
        },
      },
    })
      .then((res) => {
        if (res?.data?.message) {
          message?.info(res.data.message);
        }
        setExportLoading(false);
      })
      .catch((error) => {
        message?.error(error?.response?.data?.error || 'got some problem');
        setExportLoading(false);
      });
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setSponsorFilter(initialSponsorFilter);
    setSortedInfo({});
    fetchSponsorData({
      variables: { filter: initialSponsorFilter },
    });
  };

  const exportContent = (
    <Menu onClick={handleExport}>
      <Menu.Item key="SELECTED" id="selected-export">
        <span>Selected</span>
      </Menu.Item>
      <Menu.Item key="ALL" id="all-export">
        <span>All</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="sponsors"
        folder="SPONSOR"
      />

      <Portal portalId="header-right-content">
        <Button
          id="sponsor-table-add-btn"
          type="primary"
          onClick={handleAddEditSponsor}
        >
          Add Sponsor
        </Button>
      </Portal>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            id="search-container-id"
            placeholder="Sponsor name or other detail.."
            name="Sponsors"
            getData={onSearchChange}
          />
        </div>
        <div className="header-buttons">
          <Button
            className="common-button import-button"
            id="sponsor-table-import-btn"
            type="primary"
            onClick={() => handleShowImportModal(true)}
          >
            Import
          </Button>
          <Dropdown
            overlayClassName="export-btn-dropdown"
            overlay={exportContent}
            placement="bottomCenter"
          >
            <Button
              className="common-button export-button"
              id="sponsor-table-export-btn"
              loading={exportLoading}
              type="primary"
            >
              Export
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={loading}
            columns={columns}
            data={data?.sponsors?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
            rowSelection={rowSelection}
          />
        )}
      </div>
    </div>
  );
};

export default SponsorTable;
