import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { formValidatorRules } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import { GRANT_TENANT_ACCESS_TO_ADMIN } from '../graphql/Mutations';

const LoginModal = ({
  showLoginModal = false,
  setShowLoginModal,
  id = null,
}) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const [btnLoading, setBtnLoading] = useState(false);

  const { requiredWhiteSpaceAllowed, required, email } = formValidatorRules;

  const [grantTenantAccessToAdmin] = useMutation(GRANT_TENANT_ACCESS_TO_ADMIN, {
    onError() {
      setBtnLoading(false);
    },
  });

  const onFinish = async (values) => {
    setBtnLoading(true);
    try {
      const response = await grantTenantAccessToAdmin({
        variables: { data: { password: values?.password }, where: { id } },
      });
      if (response?.data?.grantTenantAccessToAdmin) {
        setBtnLoading(false);
        // eslint-disable-next-line no-undef
        window
          .open(response?.data?.grantTenantAccessToAdmin, '_blank')
          ?.focus();
        setShowLoginModal(false);
      }
    } catch {
      setBtnLoading(false);
    }
  };

  return (
    <ModalComponent
      title="Enter your password"
      open={showLoginModal}
      wrapClassName="tenant-login-modal"
      footer={null}
      onCancel={() => setShowLoginModal(false)}
      destroyOnClose
    >
      <Form
        name="Login"
        onFinish={onFinish}
        className="gx-login-form gx-form-row0"
      >
        <Form.Item
          name="email"
          initialValue={currentUser?.email}
          rules={[requiredWhiteSpaceAllowed, email]}
        >
          <Input
            disabled
            prefix={<UserOutlined className="icon-color" />}
            placeholder="Email"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[required]}
          normalize={(value) => value?.split(' ')?.join('')}
        >
          <Input.Password
            prefix={<LockOutlined className="icon-color" />}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item className="gx-text-center">
          <Button
            loading={btnLoading}
            type="primary"
            className="fill-width"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default LoginModal;
