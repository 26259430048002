import React from 'react';
import {
  formatPhoneNumber,
  formatPhoneNumberWithoutMask,
} from '../../../common/utils';

// eslint-disable-next-line import/prefer-default-export
export const sponsorColumns = [
  {
    title: 'NAME',
    ellipsis: true,
    width: 130,
    dataIndex: 'firstName',
    key: 'firstName',
    className: 'max-width-column',
    render: (text, record) => `${record?.firstName} ${record?.lastName}`,
  },
  {
    title: 'EMAIL',
    ellipsis: true,
    dataIndex: 'email',
    className: 'max-width-column',
    key: 'email',
    render: (email) => <span title={email}>{email || '-'} </span>,
  },
  {
    title: 'BUSINESS NAME',
    ellipsis: true,
    dataIndex: 'businessName',
    className: 'max-width-column',
    key: 'businessName',
    render: (businessName) => (
      <span title={businessName}>{businessName || '-'} </span>
    ),
  },
  {
    title: 'PHONE NUMBER',
    width: 180,
    ellipsis: true,
    dataIndex: 'phoneNo',
    key: 'phoneNo',
    render: (phone) =>
      formatPhoneNumber(formatPhoneNumberWithoutMask(phone)?.slice(-10)) || '-',
  },
];
