import { gql } from '@apollo/client';

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        label
        industryCode
        refData
        key
        tenantId
        description
        isActive
        id
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const INDUSTRY_FILTER = gql`
  query industryFilters($filter: IndustryFilter!) {
    industryFilters(filter: $filter) {
      count
      data {
        label
        industryCode
        refData
        key
        tenantId
        isActive
      }
    }
  }
`;

export const GET_INDUSTRY = gql`
  query industry($where: IndustryWhereInput!) {
    industry(where: $where) {
      label
      industryCode
      refData
      tenantId
      key
      description
      isActive
      id
      uuid
      logo {
        url
        key
        name
        extension
        contentType
      }
    }
  }
`;
