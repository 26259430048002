import { gql } from '@apollo/client';

export const GET_SPONSORS = gql`
  query sponsors($filter: SponsorFilter!, $where: SponsorsWhereInput) {
    sponsors(filter: $filter, where: $where) {
      count
      data {
        id
        firstName
        lastName
        businessName
        email
        subDomain
        phoneNo
        status
        pickListStatus {
          label
        }
      }
    }
  }
`;

export const GET_SPONSOR = gql`
  query sponsor($id: ID!) {
    sponsor(where: { id: $id }) {
      id
      firstName
      lastName
      businessName
      email
      subDomain
      phoneNo
      status
      pickListStatus {
        label
      }
      industry {
        key
        id
        label
      }
      lineOfBusiness {
        key
        id
        label
        industryId
      }
      location {
        addressLine1
        addressLine2
        addressLine3
        city
        state
        country
        zipCode
      }
    }
  }
`;

export const ADMIN_PICK_LIST_FILTER = gql`
  query adminPickListFilters($filter: PickListFilters!) {
    adminPickListFilters(filter: $filter) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const FETCH_STATE_CITY = gql`
  query fetchStatesAndCity($filter: USLocationTypeFilter) {
    getLocationType(filter: $filter) {
      data {
        id
        name
        state
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        isActive
        lobSaData {
          id
          label
          key
          isActive
        }
      }
    }
  }
`;

export const GET_PICK_LISTS = gql`
  query pickLists($filter: PickListFilter!, $where: PickListWhereFilter!) {
    pickLists(filter: $filter, where: $where) {
      count
      data {
        label
        id
        key
      }
    }
  }
`;
