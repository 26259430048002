import { gql } from '@apollo/client';

export const GET_API_LOGS = gql`
  query restApiLogs($filter: RestApiLogFilter!, $where: RestApiLogWhereFilter) {
    restApiLogs(filter: $filter, where: $where) {
      count
      data {
        id
        token
        requestApi
        method
        tenant
        sponsor
        statusCode
        publicIpAddress
        createdAt
      }
    }
  }
`;

export const GET_API_LOG = gql`
  query restApiLog($where: RestApiLogWhereInput!) {
    restApiLog(where: $where) {
      id
      token
      requestApi
      method
      tenant
      sponsor {
        businessName
      }
      input
      error
      statusCode
      beforeData
      afterData
      publicIpAddress
      createdAt
      updatedAt
    }
  }
`;

export const GET_LOGS_FILTER = gql`
  query restApiLogFilters($filter: RestApiLogFilter!) {
    restApiLogFilters(filter: $filter) {
      count
      data {
        token
        requestApi
        method
        tenant
        publicIpAddress
        createdAt
      }
    }
  }
`;

export const TENANT_FILTER = gql`
  query tenantFilters($filter: TenantFilter!) {
    tenantFilters(filter: $filter) {
      count
      data {
        name
        subDomain
        phoneNo
        email
        isActive
      }
    }
  }
`;

export const SPONSORS_FILTER = gql`
  query sponsors($filter: SponsorFilter!) {
    sponsors(filter: $filter) {
      count
      data {
        id
        businessName
      }
    }
  }
`;
