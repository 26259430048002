import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import GoBackButton from '../../../../components/GoBackButton';
import Portal from '../../../../components/Portal';
import '../../industry.less';
import SubAreaTable from '../components/SubAreaTable';
import { GET_LINE_OF_BUSINESS } from '../graphql/Queries';

const SubAreaTableList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);

  const { lobId } = useParams();

  const { data: { lineOfBusiness } = {} } = useQuery(GET_LINE_OF_BUSINESS, {
    variables: { id: lobId },
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  return (
    <Card className="full-height-card card-body-padding industry-module">
      <Portal portalId="header-left-content">
        <span className="portal-header">
          <GoBackButton />
          {lineOfBusiness?.label}
        </span>
      </Portal>
      {pageSize && <SubAreaTable />}
    </Card>
  );
};

export default SubAreaTableList;
