import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  message,
  Row,
  Space,
} from 'antd';
import { debounce, every, filter, find, findIndex, forEach, map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { REGEX, ROUTES, SKIP_RECORD } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CustomPhoneInput from '../../../components/CustomPhoneInput';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import NumberComponent from '../../../components/NumberComponent';
import Portal from '../../../components/Portal';
import RouterPrompt from '../../../components/RouterPrompt';
import SelectComponent from '../../../components/SelectComponent';
import useRouter from '../../../hooks/useRouter';
import {
  FETCH_STATE_CITY,
  GET_INDUSTRIES,
  GET_PICK_LISTS,
} from '../graphql/Queries';

const CheckboxGroup = Checkbox.Group;

let stateScrollDebounce;
let cityScrollDebounceJob;

const stateCityFilter = {
  skip: 0,
  limit: 20,
  type: 'STATE',
  search: '',
  sortOn: 'name',
  sortBy: 'ASC',
};

const { Option } = SelectComponent;

const { email, subDomain, required, requiredWhiteSpaceAllowed, zipCode } =
  formValidatorRules;

const SponsorForm = (props) => {
  const { sponsorData, handleSubmit, isSubmit, isEdit = false } = props;
  const { params: { id } = {}, navigate } = useRouter();
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [fetchCity, setFetchCity] = useState(false);
  const [citySearchFlag, setCitySearchFlag] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [stateSearchFlag, setStateSearchFlag] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [disableCity, setDisableCity] = useState(true);
  const [disableState, setDisableState] = useState(false);
  const [callAsync, setCallAsync] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [fileList, setFileList] = useState([]);
  const [stateIsEnd, setStateIsEnd] = useState(false);
  const [cityIsEnd, setCityIsEnd] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  // industry, lob and service type
  const [loading, setLoading] = useState(true);
  const [checkBoxArray, setCheckBoxArray] = useState([]);

  const [form] = Form?.useForm();
  const [fetchStateAndCity] = useLazyQuery(FETCH_STATE_CITY, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const moreData = response?.getLocationType?.data;
      if (fetchCity) {
        setCityIsEnd(moreData?.length < SKIP_RECORD);
        if (citySearchFlag) {
          setCities([...moreData]);
        } else {
          setCities([...cities, ...moreData]);
        }
        setCityLoading(false);
      } else {
        setStateIsEnd(moreData?.length < SKIP_RECORD);
        if (stateSearchFlag) {
          setStates([...moreData]);
        } else {
          setStates([...states, ...moreData]);
        }
        setStateLoading(false);
        setCallAsync(false);
      }
    },
    onError() {
      setStateLoading(false);
      setCityLoading(false);
    },
  });

  const { data } = useQuery(GET_INDUSTRIES, {
    variables: {
      filter: {
        justShow: false,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const checkboxArray = [];
      if (!isEdit) {
        forEach(res?.industries?.data, (industry) => {
          const activeData = filter(
            industry?.lobSaData,
            (item) => item?.isActive,
          );
          checkboxArray?.push({
            indeterminate:
              activeData?.length > 0
                ? !!(industry?.lobSaData?.length !== activeData?.length)
                : false,
            checkedAll: !!(industry?.lobSaData?.length === activeData?.length),
            checkedChildren: map(industry?.lobSaData, (lob) => {
              if (lob?.isActive === true) {
                return lob?.id;
              }
            }),
            id: industry?.id,
            key: industry?.key,
          });
        });
      } else {
        forEach(res?.industries?.data, (industry) => {
          const checkedChildren = [];
          const sameIndustryObj = find(
            sponsorData?.industry,
            (item) => item?.key === industry?.key,
          );
          forEach(sponsorData?.lineOfBusiness, (lob) => {
            const sameLobObj = find(
              industry?.lobSaData,
              (item) => item?.key === lob?.key,
            );
            if (industry?.key === sameIndustryObj?.key && sameLobObj) {
              checkedChildren?.push(sameLobObj?.id);
            }
          });
          checkboxArray?.push({
            id: industry?.id,
            key: industry?.key,
            checkedChildren,
            indeterminate:
              checkedChildren?.length > 0
                ? industry?.lobSaData?.length !== checkedChildren?.length
                : false,
            checkedAll:
              checkedChildren?.length > 0
                ? industry?.lobSaData?.length === checkedChildren?.length
                : false,
          });
        });
      }
      setCheckBoxArray(checkboxArray);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const { data: pickListsData } = useQuery(GET_PICK_LISTS, {
    skip: !isEdit,
    variables: {
      filter: {
        skip: 0,
        sortOn: 'label',
        sortBy: 'ASC',
      },
      where: {
        sponsorId: id,
        entityName: 'Sponsor',
        fieldKey: 'STATUS',
      },
    },
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  useEffect(() => {
    if (!callAsync && states?.length > 0) {
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: sponsorData?.state,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAsync]);

  useEffect(() => {
    if (!sponsorData?.state) {
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter,
        },
      });
    }
    if (sponsorData?.country) {
      setDisableState(false);
    }
    if (sponsorData?.state) {
      setDisableCity(false);
      setCitySearchFlag(true);
      setCallAsync(true);
      setSelectedState(sponsorData?.state);
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter,
        },
      });
    }
    if (sponsorData?.profileImage && !fileList?.length) {
      setFileList([
        {
          url: sponsorData?.profileImage?.url,
          title: sponsorData?.profileImage?.title,
        },
      ]);
    }

    // eslint-disable-next-line no-undef
    window.onbeforeunload = function () {
      return true;
    };
    return () => {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCityBlur = () => {
    setSearchValue('');
    setCityIsEnd(false);
  };

  const handleStateBlur = () => {
    setStateIsEnd(false);
  };

  const handleCityClear = () => {
    setFetchCity(true);
    fetchStateAndCity({
      variables: {
        filter: {
          ...stateCityFilter,
          type: 'CITY',
          state: selectedState,
        },
      },
    });
  };

  const onStateScroll = (event) => {
    setFetchCity(false);
    setStateSearchFlag(false);
    if (stateScrollDebounce) {
      stateScrollDebounce?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    stateScrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !stateIsEnd) {
        setStateLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: states?.length,
              search: searchValue,
              type: 'STATE',
            },
          },
        });
      }
    }, 500);

    stateScrollDebounce();
  };

  const onCityScroll = (event) => {
    setCitySearchFlag(false);
    setFetchCity(true);
    if (cityScrollDebounceJob) {
      cityScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    cityScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !cityIsEnd) {
        setCityLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: cities?.length,
              type: 'CITY',
              search: searchValue,
              state: selectedState,
            },
          },
        });
      }
    }, 500);

    cityScrollDebounceJob();
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
    if (every(checkBoxArray, (item) => item?.checkedChildren?.length < 1)) {
      message?.error(
        'Please select at least one Industry/Line Of Business/Service Type',
      );
    }
  };

  const handleStateChange = (value) => {
    setFetchCity(false);
    setSearchValue(value);
    setStateSearchFlag(true);
    const state = form?.getFieldValue('state');
    if (value) {
      setStateLoading(true);
      setCities([]);
      setDisableCity(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
            search: value,
          },
        },
      });
    } else {
      if (!state?.length) {
        setStateLoading(true);
        setDisableCity(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter,
          },
        });
      }
      if (state?.length > 0) {
        setDisableCity(false);
        setCitySearchFlag(true);
        setCallAsync(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter,
          },
        });
      }
    }
  };

  const handleStateSelect = (value) => {
    if (value) {
      form?.setFieldsValue({
        city: null,
      });
      setSelectedState(value);
      setDisableCity(false);
      setCitySearchFlag(true);
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: value,
          },
        },
      });
      setStateLoading(false);
    } else {
      setCitySearchFlag(true);
      setStateLoading(false);
      setDisableCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
          },
        },
      });
    }
  };

  const handleSelectCountry = (value) => {
    setDisableState(!value);
  };

  const handleCityChange = (value) => {
    setFetchCity(true);
    setCitySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setCityLoading(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            search: value,
            state: form?.getFieldValue('state'),
          },
        },
      });
    } else {
      setCityLoading(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: form?.getFieldValue('state'),
          },
        },
      });
    }
  };

  const handleCountryClear = () => {
    form?.setFieldsValue({
      country: null,
      state: null,
      city: null,
    });
    setDisableState(true);
    setDisableCity(true);
  };

  const onChange = (list, industry, index) => {
    const checkboxArrayCopy = [...checkBoxArray];
    if (list?.length > 0) {
      checkboxArrayCopy[index].checkedChildren = list;
    } else {
      checkboxArrayCopy[index].checkedChildren = [];
    }
    if (list?.length === industry?.lobSaData?.length) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = true;
    } else if (list?.length === 0) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = false;
    } else {
      checkboxArrayCopy[index].indeterminate = true;
      checkboxArrayCopy[index].checkedAll = false;
    }
    setCheckBoxArray(checkboxArrayCopy);
  };

  const onCheckAllChange = (e, industry, index) => {
    const checkboxArrayCopy = [...checkBoxArray];
    const checked = [];
    if (e?.target?.checked) {
      forEach(industry?.lobSaData, (area) => checked?.push(area?.id));
    }
    checkboxArrayCopy[index].indeterminate = false;
    checkboxArrayCopy[index].checkedAll = e?.target?.checked;
    checkboxArrayCopy[index].checkedChildren = checked;
    setCheckBoxArray(checkboxArrayCopy);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStateHandler = useCallback(
    debounce(handleStateChange, 500),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCityHandler = useCallback(debounce(handleCityChange, 500), []);

  const handleStateClear = () => {
    form?.setFieldsValue({
      state: null,
      city: null,
    });
    setFetchCity(false);
    fetchStateAndCity({
      variables: {
        filter: stateCityFilter,
      },
    });
    setSelectedState('');
    setCities([]);
    setDisableCity(true);
  };

  return (
    <div>
      <RouterPrompt when={showPrompt} />
      <Portal portalId="header-right-content">
        <Space>
          <Button onClick={() => navigate(ROUTES?.SPONSORS)}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmit}
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Space>
      </Portal>
      <Form
        form={form}
        initialValues={sponsorData}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          if (
            every(checkBoxArray, (item) => item?.checkedChildren?.length < 1)
          ) {
            message?.error(
              'Please select at least one Industry/Line Of Business/Service Type',
            );
            return;
          }
          setShowPrompt(false);
          handleSubmit(values, checkBoxArray);
        }}
        onValuesChange={() => setShowPrompt(true)}
        onFinishFailed={onFinishFailed}
      >
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider" />
        <Row gutter={16} className="required-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[{ ...required, message: 'Please Enter First Name' }]}
              name="firstName"
              label="First Name"
            >
              <InputComponent
                disabled={isEdit}
                allowClear
                placeholder="Enter First name"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[{ ...required, message: 'Please Enter Last Name' }]}
              name="lastName"
              label="Last Name"
            >
              <InputComponent
                disabled={isEdit}
                allowClear
                placeholder="Enter Last Name"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              label="Phone Number"
              name="phoneNo"
              rules={[
                { ...required, message: 'Please Enter Phone Number' },
                () => ({
                  validator(rule, value) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      value = value?.split(' ')?.join('');
                      const numberPattern = REGEX?.PHONE;
                      if (!numberPattern?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'should be a valid phone number',
                        );
                      }
                    }
                    return Promise?.resolve();
                  },
                }),
              ]}
            >
              <CustomPhoneInput
                placeholder="(999) 999-9999"
                disabled={isEdit}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[{ ...required, message: 'Please Enter Email' }, email]}
              name="email"
              label="Email"
            >
              <InputComponent
                disabled={isEdit}
                allowClear
                placeholder="Enter Email"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[{ ...required, message: 'Please Enter Business Name' }]}
              name="businessName"
              label="Business Name"
            >
              <InputComponent
                disabled={isEdit}
                allowClear
                placeholder="Enter Business name"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[
                { ...required, message: 'Please Enter SubDomain' },
                subDomain,
              ]}
              name="subDomain"
              label="Sub Domain"
            >
              <InputComponent
                disabled={isEdit}
                allowClear
                placeholder="Enter Sub Domain"
              />
            </Form.Item>
          </Col>
          {isEdit && (
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status',
                  },
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  {map(pickListsData?.pickLists?.data, (item) => (
                    <Option value={item?.id} key={item?.key}>
                      {item?.label}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
          )}
        </Row>
        <span className="form-divider-text">INDUSTRY - LINE OF BUSINESS</span>
        <Divider className="form-divider" />
        {loading ? (
          <LoaderComponent />
        ) : (
          data?.industries?.data?.length > 0 && (
            <div className="d-flex flex-wrap block-parent">
              {map(data?.industries?.data, (industry) => {
                if (industry?.isActive) {
                  return (
                    <div className="industry-block" key={industry?.key}>
                      <Checkbox
                        className="common-checkbox"
                        id={industry?.key}
                        indeterminate={
                          find(
                            checkBoxArray,
                            (item) => item?.key === industry?.key,
                          )?.indeterminate
                        }
                        onChange={(e) =>
                          onCheckAllChange(
                            e,
                            industry,
                            findIndex(
                              checkBoxArray,
                              (item) => item?.key === industry?.key,
                            ),
                          )
                        }
                        checked={
                          find(
                            checkBoxArray,
                            (item) => item?.key === industry?.key,
                          )?.checkedAll
                        }
                        disabled={
                          isEdit
                            ? filter(
                                sponsorData?.industry,
                                (item) => item?.key === industry?.key,
                              )?.length > 0
                            : false
                        }
                      >
                        <span className="bold-label">{industry?.label}</span>
                      </Checkbox>
                      <Divider />
                      <div className="d-flex">
                        <CheckboxGroup
                          options={map(industry?.lobSaData, (area) => {
                            if (area?.isActive) {
                              return {
                                label: area?.label,
                                value: area?.id,
                                disabled: isEdit
                                  ? filter(
                                      sponsorData?.lineOfBusiness,
                                      (item) => item?.key === area?.key,
                                    )?.length > 0
                                  : false,
                              };
                            }
                          })}
                          value={
                            find(
                              checkBoxArray,
                              (item) => item?.key === industry?.key,
                            )?.checkedChildren
                          }
                          className="common-checkbox subarea-group"
                          onChange={(list) =>
                            onChange(
                              list,
                              industry,
                              findIndex(
                                checkBoxArray,
                                (item) => item?.key === industry?.key,
                              ),
                            )
                          }
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )
        )}
        <Row gutter={16}>
          <span className="form-divider-text optional-divider">OPTIONAL</span>
          <Divider className="form-divider optional-divider" />
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="addressLine1" label="AddressLine 1">
              <InputComponent
                disabled={isEdit}
                allowClear
                placeholder="Enter Address"
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="country" label="Country">
              <SelectComponent
                disabled={isEdit}
                placeholder="Select Country"
                onSelect={handleSelectCountry}
                onClear={handleCountryClear}
                allowClear
              >
                <Option key="USA" value="USA">
                  USA
                </Option>
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="state" label="State">
              <SelectComponent
                placeholder="Select State"
                disabled={isEdit || disableState}
                allowClear
                notFoundContent={
                  stateLoading ? (
                    <LoaderComponent size="small" />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onSearch={debouncedStateHandler}
                onClear={handleStateClear}
                onSelect={handleStateSelect}
                onPopupScroll={onStateScroll}
                onBlur={handleStateBlur}
              >
                {map(states, (state) => (
                  <Option key={state?.id} value={state?.name}>
                    {state?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="city" label="City">
              <SelectComponent
                placeholder="Select City"
                disabled={isEdit || disableCity}
                notFoundContent={
                  cityLoading ? (
                    <LoaderComponent size="small" />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onSearch={debouncedCityHandler}
                onPopupScroll={onCityScroll}
                onClear={handleCityClear}
                onBlur={handleCityBlur}
              >
                {cities?.map((city) => (
                  <Option key={city?.id} value={city?.name}>
                    {city?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="zipCode" rules={[zipCode]} label="Postal Code">
              <NumberComponent
                disabled={isEdit}
                placeholder="Enter Postal code"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SponsorForm;
