import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import useRouter from '../hooks/useRouter';

const GoBackButton = ({ customLink = '' }) => {
  const { navigate } = useRouter();

  if (customLink) {
    return (
      <Button className="b-0 p-8" onClick={() => navigate(customLink)}>
        <LeftOutlined />
      </Button>
    );
  }
  return (
    <Button className="b-0 p-8" onClick={() => navigate(-1)}>
      <LeftOutlined />
    </Button>
  );
};

export default GoBackButton;
