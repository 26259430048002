import Icon, {
  BankOutlined,
  FileSyncOutlined,
  HistoryOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logoWithText from '../../../assets/images/logo-with-text.png';
import logo from '../../../assets/images/logo.png';
import { BREAKPOINTS, MODULES, ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import lobIconComponent from './iconComponent/lobIconComponent';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const menuProfileItems = [
  getItem(MODULES?.PROFILE, MODULES?.PROFILE, <UserOutlined />, [
    getItem(MODULES?.PROFILE, ROUTES?.PROFILE),
    getItem(MODULES?.LOGOUT, ROUTES?.LOGOUT),
  ]),
];

const LobIcon = (props) => <Icon component={lobIconComponent} {...props} />;

const menuItems = [
  getItem(
    MODULES?.USERS_MANAGEMENT,
    MODULES?.USERS_MANAGEMENT,
    <UserOutlined />,
    [getItem(MODULES?.USERS, ROUTES?.USERS)],
  ),
  getItem(MODULES?.TENANTS, ROUTES?.TENANTS, <BankOutlined />),
  getItem(MODULES?.SPONSORS, ROUTES?.SPONSORS, <BankOutlined />),
  getItem(MODULES?.INDUSTRIES, ROUTES?.INDUSTRIES, <LobIcon />),
  getItem(
    MODULES?.IMPORT_EXPORT_QUEUE,
    ROUTES?.IMPORT_EXPORT_QUEUE,
    <FileSyncOutlined />,
  ),
  getItem(MODULES?.PLANS, ROUTES?.PLANS, <WalletOutlined />),
  getItem(MODULES?.API_LOGS, ROUTES?.API_LOGS, <HistoryOutlined />),
];
const { Sider } = Layout;

function Sidebar() {
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const [collapsed, setCollapsed] = useState(false);
  const [isActive, setActive] = useState(false);

  const {
    navigate,
    location: { pathname },
  } = useRouter();
  const onMenuSelect = (e) => {
    navigate(e?.key);
  };
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      setDesktop(window.innerWidth > BREAKPOINTS.tablet);
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  const handleOverlay = () => {
    setActive(!isActive);
  };

  return (
    <Sider
      className="main-sidebar"
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div className="gx-layout-sider-header">
        {!isDesktop && (
          <span
            className={
              isActive ? 'active overlay-responsive' : 'overlay-disable'
            }
            onClick={handleOverlay}
          />
        )}

        <div>
          <Link to={ROUTES?.MAIN}>
            <img
              src={collapsed ? logo : logoWithText}
              alt="logo"
              className="gx-site-logo"
            />
          </Link>
        </div>
        {collapsed && (
          <div onClick={toggle} className="gx-icon-out-btn icon pointer">
            <span className="sidebar-icons">
              <RightCircleOutlined />
            </span>
          </div>
        )}
        <div onClick={toggle} className="gx-icon-btn icon pointer">
          {!collapsed && (
            <span className="sidebar-icons">
              <LeftCircleOutlined />
            </span>
          )}
        </div>
      </div>
      <div className="gx-sidebar-content">
        <Menu
          mode="vertical"
          selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
          defaultSelectedKeys={[ROUTES?.MAIN]}
          onSelect={onMenuSelect}
          onClick={onMenuSelect}
          items={menuItems}
        />
      </div>
      <div className="gx-linebar">
        <Menu
          mode="vertical"
          selectedKeys={[`/${pathname?.split('/')[1]}`]}
          defaultSelectedKeys={[ROUTES?.JOBS]}
          onSelect={onMenuSelect}
          items={menuProfileItems}
        />
        {/* <div className="gx-icon-btn icon pointer" onClick={toggle}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div> */}
      </div>
    </Sider>
  );
}

export default Sidebar;
