import { gql } from '@apollo/client';

export const GET_LINE_OF_BUSINESSES = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        lobCode
        key
        description
        isActive
        tenantId
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const GET_LINE_OF_BUSINESS = gql`
  query lineOfBusiness($id: ID!) {
    lineOfBusiness(where: { id: $id }) {
      id
      label
      lobCode
      key
      description
      isActive
      tenantId
      uuid
      industry {
        label
      }
      logo {
        url
        key
        name
        extension
        contentType
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        lobCode
        key
        description
        isActive
        tenantId
      }
    }
  }
`;

export const GET_SUB_AREAS = gql`
  query subAreas($filter: SubAreaFilter!, $where: SubAreasWhereFilter) {
    subAreas(filter: $filter, where: $where) {
      count
      data {
        id
        label
        saCode
        key
        description
        estimation
        isActive
        waiverText
        priceGuaranteeText
        offerText
        lineOfBusinessId
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const GET_SUB_AREA = gql`
  query subArea($id: ID!) {
    subArea(where: { id: $id }) {
      id
      label
      saCode
      key
      description
      estimation
      isActive
      waiverText
      priceGuaranteeText
      offerText
      lineOfBusinessId
      uuid
      lineOfBusiness {
        id
        label
        key
        lobCode
        description
        isActive
        tenantId
      }
      industry {
        label
      }
      logo {
        url
        key
        name
        extension
        contentType
      }
    }
  }
`;

export const SUB_AREA_FILTER = gql`
  query subAreaFilters($filter: SubAreaFilter!) {
    subAreaFilters(filter: $filter) {
      count
      data {
        label
        saCode
        key
        description
        estimation
        isActive
        waiverText
        priceGuaranteeText
        offerText
        lineOfBusinessId
      }
    }
  }
`;
