import { useLazyQuery } from '@apollo/client';
import { Button, Checkbox, Divider, Empty, Tag } from 'antd';
import { debounce, filter, forEach, map, trim } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import FilterSelectedIcon from '../../../../assets/svg/filter-selected.svg';
import FilterIcon from '../../../../assets/svg/filter.svg';
import { ROUTES, SKIP_RECORD } from '../../../../common/constants';
import { strippedString } from '../../../../common/utils';
import LoaderComponent from '../../../../components/LoaderComponent';
import SearchComponent from '../../../../components/SearchComponent';
import TableComponent from '../../../../components/TableComponent';
import {
  GET_LINE_OF_BUSINESSES,
  LINE_OF_BUSINESS_FILTER,
} from '../graphql/Queries';

let scrollDebounce = null;

const LineOfBusinessTable = () => {
  const {
    state: { pageSize, filterData },
  } = useContext(AppContext);
  const { industryId } = useParams();
  const navigate = useNavigate();

  const initialPaginationValue = useMemo(
    () => ({
      total: 0,
      current: 1,
    }),
    [],
  );

  const initialLineOfBusinessFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'createdAt',
      sortBy: 'DESC',
      justShow: false,
      industryId,
    }),
    [pageSize, industryId],
  );

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  // const [showImportModal, setShowImportModal] = useState(false);// commented for now because of mvp.
  const [sortedInfo, setSortedInfo] = useState({});
  // const [selectedKeys, setSelectedKeys] = useState([]); // commented for now because of mvp.
  const [lineOfBusinessFilter, setLineOfBusinessFilter] = useState(
    initialLineOfBusinessFilter,
  );
  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  // const [exportLoading, setExportLoading] = useState(false); // commented for now because of mvp.

  // commented as no need now
  // const [updateLineOfBusinessMutate, { loading: updateLineOfBusinessLoading }] =
  //   useMutation(UPDATE_LINE_OF_BUSINESS, {
  //     onError() {},
  //   });

  // commented for now because of mvp.
  // const [
  //   deleteLineOfBusiness,
  //   { loading: deleteLineOfBusinessLoading }
  // ] = useMutation(DELETE_LINE_OF_BUSINESS, {
  //   onError() {}
  // });

  const [fetchLineOfBusinessData, { loading, data }] = useLazyQuery(
    GET_LINE_OF_BUSINESSES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.lineOfBusinesses?.count,
        };
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );

  const [lineOfBusinessFilters] = useLazyQuery(LINE_OF_BUSINESS_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.lineOfBusinessFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  useEffect(() => {
    fetchLineOfBusinessData({
      variables: {
        filter: lineOfBusinessFilter,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchLineOfBusinessData({
      variables: {
        filter: { ...lineOfBusinessFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setFilters(filtersCopyState);
    setLineOfBusinessFilter({
      ...lineOfBusinessFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        default:
          lineOfBusinessFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
                justShow: false,
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  // commented for now because of mvp.
  // const handleShowImportModal = (visible) => {
  //   setShowImportModal(visible);
  // };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          default:
            lineOfBusinessFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                  justShow: false,
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  // commented for now because of mvp.
  // const rowSelection = {
  //   fixed:  'left',
  //   selectedRowKeys: selectedKeys,
  //   onChange: (selectedRowKeys) => {
  //     setSelectedKeys(selectedRowKeys);
  //   }
  // };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };
    setFilters(filtersCopy);
    setFiltersCopyState(filtersCopy);
    fetchLineOfBusinessData({
      variables: {
        filter: {
          ...lineOfBusinessFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC',
        },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });
    setLineOfBusinessFilter({
      ...lineOfBusinessFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  // commented as no need now
  // const handleAddEditLineOfBusiness = (record) => {
  //   navigate(`${ROUTES?.INDUSTRIES}/edit/${industryId}/edit/${record?.id}`);
  // };

  // const handleViewLineOfBusiness = (record) => {
  //   navigate(`${ROUTES?.INDUSTRIES}/view/${industryId}/view/${record?.id}`);
  // };

  // commented for now because of mvp.
  // const importCallback = () => {
  //   setPaginationProp(initialPaginationValue);
  //   setLineOfBusinessFilter(initialLineOfBusinessFilter);
  //   setSortedInfo({});
  //   fetchLineOfBusinessData({
  //     variables: { filter: initialLineOfBusinessFilter }
  //   });
  // };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setLineOfBusinessFilter({
        ...lineOfBusinessFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchLineOfBusinessData({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setLineOfBusinessFilter({
        ...lineOfBusinessFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      fetchLineOfBusinessData({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      default:
        lineOfBusinessFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex,
              justShow: false,
            },
          },
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters, close }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'isActive' && (
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => (
                <Tag
                  key={item?.toString()}
                  closable
                  onClose={() => handleDeleteFilter(item, dataIndex)}
                  className="filter-tag"
                >
                  {dataIndex === 'isActive' ? (
                    <span title={item === true ? 'Active' : 'Inactive'}>
                      {item === true ? 'Active' : 'Inactive'}
                    </span>
                  ) : (
                    <span title={item?.label || item?.toString()}>
                      {item?.label || item?.toString()}
                    </span>
                  )}
                </Tag>
              ))}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => (
                <div className="single-checkbox-div" key={item?.key || item}>
                  <Checkbox
                    value={item?.key || item}
                    checked={filtersCopyState?.[dataIndex]?.includes(
                      item?.key || item,
                    )}
                    key={item?.key || item}
                    onChange={(e) => changeFilter(e, dataIndex)}
                    className="single-checkbox"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    )}
                  </Checkbox>
                </div>
              ))
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex, close)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters && filters[dataIndex]?.length > 0 ? (
        <img src={FilterSelectedIcon} alt="filter-icon" width={16} />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  // commented for now because of mvp.
  // const handleDeleteLineOfBusiness = async (editLineOfBusinessData) => {
  //   const response = await deleteLineOfBusiness({
  //     variables: { where: { id: editLineOfBusinessData?.id } }
  //   });
  //   if (response?.data?.deleteLineOfBusiness) {
  //     fetchLineOfBusinessData({
  //       variables: {
  //         filter: lineOfBusinessFilter,
  //         ...(filters && { where: filters })
  //       }
  //     });
  //   }
  // };

  // commented as no need now
  // const handleLineOfBusinessStatus = async (editLineOfBusinessData) => {
  //   const editData = {
  //     ...editLineOfBusinessData,
  //     isActive: !editLineOfBusinessData.isActive,
  //     logo: { ...editLineOfBusinessData?.logo, __typename: undefined },
  //   };
  //   const dataToSend = omit(editData, [
  //     'id',
  //     'referenceId',
  //     'tenantId',
  //     'key',
  //     '__typename',
  //     'lobCode',
  //   ]);
  //   const response = await updateLineOfBusinessMutate({
  //     variables: {
  //       data: dataToSend,
  //       where: { id: editLineOfBusinessData?.id },
  //     },
  //   });
  //   if (response?.data?.updateLineOfBusiness) {
  //     fetchLineOfBusinessData({
  //       variables: {
  //         filter: lineOfBusinessFilter,
  //         ...(filters && { where: filters }),
  //       },
  //     });
  //   }
  // };

  // commented as no need now
  // const renderActionButtons = (editLineOfBusinessData) => (
  //   <div className="d-flex flex-vertical">
  //     {isEdit && (
  //       <Button
  //         id="lineOfBusiness-table-edit-btn"
  //         className="b-0"
  //         onClick={() => handleAddEditLineOfBusiness(editLineOfBusinessData)}
  //       >
  //         Edit
  //       </Button>
  //     )}
  //     <Button
  //       id="lineOfBusiness-table-view-btn"
  //       className="b-0"
  //       onClick={() => handleViewLineOfBusiness(editLineOfBusinessData)}
  //     >
  //       View
  //     </Button>
  //     {/* commented for now because of mvp */}
  //     {/* <Popconfirm
  //         title="Are you sure to delete?"
  //         onConfirm={() => handleDeleteLineOfBusiness(editLineOfBusinessData)}
  //         okText="Yes"
  //         cancelText="No"
  //       >
  //         <Button id="lineOfBusiness-table-status-btn" className="b-0">
  //           Remove
  //         </Button>
  //       </Popconfirm> */}
  //     {isEdit && (
  //       <Popconfirm
  //         title={`Are you sure to ${
  //           editLineOfBusinessData?.isActive ? 'Mark Inactive' : 'Mark Active'
  //         }?`}
  //         onConfirm={() => handleLineOfBusinessStatus(editLineOfBusinessData)}
  //         okText="Yes"
  //         cancelText="No"
  //       >
  //         <Button id="lineOfBusiness-table-status-btn" className="b-0">
  //           {editLineOfBusinessData?.isActive ? 'Mark Inactive' : 'Mark Active'}
  //         </Button>
  //       </Popconfirm>
  //     )}
  //   </div>
  // );

  const columns = [
    {
      title: 'LINES OF BUSINESS',
      dataIndex: 'label',
      key: 'label',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'label' && sortedInfo?.order,
      width: 200,
      render: (label, record) => (
        <span
          className="click-label"
          title={label}
          onClick={() => {
            navigate(
              `${ROUTES?.INDUSTRIES}/${industryId}${ROUTES?.LINE_OF_BUSINESSES}/${record?.id}${ROUTES?.SUB_AREAS}`,
            );
          }}
        >
          {label}
        </span>
      ),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      className: 'max-width-column',
      ellipsis: true,
      render: (description = '') => strippedString(description) || '-',
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      width: 120,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }
        return <span>Inactive</span>;
      },
    },
    // commented as no need now
    // {
    //   dataIndex: 'id',
    //   align: 'right',
    //   width: 10,
    //   render: (id, record) => (
    //     <Popover
    //       placement="bottom"
    //       overlayClassName="action-button"
    //       content={renderActionButtons(record)}
    //     >
    //       <MoreOutlined />
    //     </Popover>
    //   ),
    // },
  ];

  const onSearchChange = (value) => {
    setLineOfBusinessFilter({
      ...lineOfBusinessFilter,
      skip: value
        ? 0
        : lineOfBusinessFilter?.limit * (paginationProp?.current - 1),
      search: trim(value),
    });
    fetchLineOfBusinessData({
      variables: {
        filter: {
          ...lineOfBusinessFilter,
          skip: value
            ? 0
            : lineOfBusinessFilter?.limit * (paginationProp?.current - 1),
          search: trim(value),
        },
        ...(filters && { where: filters }),
      },
    });
  };

  // Commented for future use
  // const onSearchChange = (value) => {
  //   setLineOfBusinessFilter({
  //     ...lineOfBusinessFilter,
  //     skip: value
  //       ? 0
  //       : lineOfBusinessFilter?.limit * (paginationProp?.current - 1),
  //     search: value
  //   });
  //   fetchLineOfBusinessData({
  //     variables: {
  //       filter: {
  //         ...lineOfBusinessFilter,
  //         skip: value
  //           ? 0
  //           : lineOfBusinessFilter?.limit * (paginationProp?.current - 1),
  //         search: value
  //       },
  //       ...(filters && { where: filters })
  //     }
  //   });
  // };

  // commented for now because of mvp.
  // const handleExport = async (e) => {
  //   const { key } = e;
  //   setExportLoading(true);
  //   await handleExportCommon({
  //     currentUser,
  //     filters,
  //     key,
  //     selectedKeys,
  //     module: 'LINEOFBUSINESS'
  //   });
  //   setExportLoading(false);
  // };

  // commented for now because of mvp.
  // const exportContent = (
  //   <Menu onClick={handleExport}>
  //     <Menu.Item key="SELECTED" id="selected-export">
  //       <span>Selected</span>
  //     </Menu.Item>
  //     <Menu.Item key="ALL" id="all-export">
  //       <span>All</span>
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <div>
      {/* commented for now because of mvp */}
      {/* <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="line-of-business"
      />
      <Portal portalId="header-right-content">
        <Button
          className="common-button"
          icon={<img src={LineOfBusinessIcon} alt="import-icon" width={12} />}
          size="small"
          id="lineOfBusiness-table-add-btn"
          type="primary"
          onClick={handleAddEditLineOfBusiness}
        >
          Add Line of Business
        </Button>
      </Portal> */}
      {/* Commented as currently not in ui but for future use */}
      {/* <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_LINE_OF_BUSINESS_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Lines of Business or other detail.."
              name="LineOfBusinesses"
              getData={onSearchChange}
            />
          </AccessControl>
        </div> */}
      {/* commented for now because of mvp */}
      {/* <div className="header-buttons">
          <Button
            size="small"
            className="common-button import-button"
            icon={<img src={ImportIcon} alt="import-icon" width={11} />}
            id="lineOfBusiness-table-import-btn"
            type="primary"
            onClick={() => handleShowImportModal(true)}
          >
            Import
          </Button>
          <Dropdown
            overlayClassName="export-btn-dropdown"
            menu={{ items:exportOptions, onClick:handleExport }}
            placement="bottom"
          >
            <Button
              className="common-button export-button"
              size="small"
              icon={<img src={ExportIcon} alt="export-icon" width={11} />}
              id="lineOfBusiness-table-export-btn"
              loading={exportLoading}
              type="primary"
            >
              Export
            </Button>
          </Dropdown>
        </div> */}
      {/* </div> */}
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            id="search-container-id"
            placeholder="Line of Business name or other detail.."
            name="Industry"
            getData={onSearchChange}
            value={searchValue}
            setSearchValue={setSearchValue}
          />
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={
              loading
              // || updateLineOfBusinessLoading
              //  || // commented for now because of mvp.
              // deleteLineOfBusinessLoading
            }
            columns={[...columns?.filter((item) => item !== false)]}
            data={data?.lineOfBusinesses?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp} // changed to false as currently pagination is not required
            // rowSelection={rowSelection} // commented for now because of mvp.
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};

export default LineOfBusinessTable;
