import { Card } from 'antd';
import React from 'react';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import SponsorTable from '../components/SponsorTable';
import '../sponsors.less';

const SponsorList = () => (
    <Card className="full-height-card card-body-p-20 sponsor-management">
      <Portal portalId="header-left-content">
        <span className="portal-header">{MODULES?.SPONSORS}</span>
      </Portal>
      <SponsorTable />
    </Card>
  );

export default SponsorList;
