import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import { forEach } from 'lodash';
import React, { useState } from 'react';
import { ROUTES } from '../../../common/constants';
import { formatPhoneNumberWithoutMask } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import withRouter from '../../../components/withRouter';
import useRouter from '../../../hooks/useRouter';
import UserForm from '../components/SponsorForm';
import SponsorTabs from '../components/SponsorTabs';
import { CREATE_SPONSOR } from '../graphql/Mutations';
import '../sponsors.less';

function SponsorCreate() {
  const { navigate } = useRouter();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [createSponsor] = useMutation(CREATE_SPONSOR, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleSubmit = async (formValues, checkBoxArray = []) => {
    setSubmitLoading(true);
    const {
      firstName,
      lastName,
      phoneNo,
      email,
      businessName,
      subDomain,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      county,
      country,
      state,
      zipCode,
    } = formValues;

    const sponsorInput = {
      firstName,
      lastName,
      phoneNo: formatPhoneNumberWithoutMask(phoneNo)?.slice(-10),
      email,
      businessName,
      subDomain,
      createFrom: 'ADMIN_PORTAL',
    };
    const locationInput = {
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      county,
      country,
      state,
      zipCode,
    };
    const userInput = {
      firstName,
      lastName,
      email,
    };

    const selectedIndustries = [];
    forEach(checkBoxArray, (item) => {
      if (item?.checkedChildren?.length > 0) {
        selectedIndustries?.push({
          id: item?.id,
          lineOfBusinessIds: item?.checkedChildren,
        });
      }
    });

    const industryLOBInput = {
      selectedIndustries,
    };

    try {
      const response = await createSponsor({
        variables: {
          data: { userInput, sponsorInput, locationInput, industryLOBInput },
        },
      });
      if (response?.data?.createSponsor) {
        navigate(ROUTES?.SPONSORS);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    country: 'USA',
  };

  return (
    <Card className="full-height-card card-body-p-24 sponsor-management">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.SPONSORS} />
          <span className="portal-header">Add Sponsor</span>
        </div>
      </Portal>
      <SponsorTabs>
        <UserForm
          sponsorData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </SponsorTabs>
    </Card>
  );
}

export default withRouter(SponsorCreate);
