import { Tabs } from 'antd';
import React from 'react';
import infoSvg from '../../../assets/svg/info.svg';
import withRouter from '../../../components/withRouter';

const UserTabs = ({ children }) => {
  const navItems = [
    {
      label: 'Information',
      key: 'INFORMATION',
      children: { ...children },
      icon: <img src={infoSvg} alt="info-icon" />,
    },
  ];

  return (
    <div className="card-container">
      <Tabs type="card" activeKey="INFORMATION" items={navItems || []} />
    </div>
  );
};

export default withRouter(UserTabs);
