import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { FEATURE_MAX_LIMIT, ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import RouterPrompt from '../../../components/RouterPrompt';
import SelectComponent from '../../../components/SelectComponent';
import TextAreaComponent from '../../../components/TextAreaComponent';
import useRouter from '../../../hooks/useRouter';

const { required, requiredWhiteSpaceAllowed } = formValidatorRules;

const { Option } = SelectComponent;

const PlanForm = (props) => {
  const [form] = Form?.useForm();
  const { planData, handleSubmit, isSubmit } = props;
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const { navigate } = useRouter;

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.onbeforeunload = function () {
      return true;
    };
    return () => {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <div>
      <RouterPrompt isPrompt={showPrompt} />
      <Portal portalId="header-right-content">
        <Space>
          <Button onClick={() => navigate(`${ROUTES?.PLANS}`)}>Cancel</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmit}
            onClick={() => form?.submit()}
          >
            Save
          </Button>
        </Space>
      </Portal>
      <Form
        form={form}
        initialValues={planData}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          setShowPrompt(false);
          handleSubmit(values);
        }}
        onValuesChange={() => setShowPrompt(true)}
        onFinishFailed={onFinishFailed}
      >
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider" />
        <Row gutter={16} className="required-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[{ ...required, message: 'Please Enter Name' }]}
              name="name"
              label="Name"
            >
              <InputComponent allowClear placeholder="Enter name" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="price"
              label="Price"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: `Please Enter Amount`,
                },
              ]}
            >
              <PriceComponent placeholder="Enter Price" isPrice prefix="$" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="isActive"
              label="Status"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Status',
                },
              ]}
            >
              <SelectComponent placeholder="Select Status" allowClear>
                <Option key="active" value>
                  Active
                </Option>
                <Option key="inactive" value={false}>
                  InActive
                </Option>
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="typeOfPlan"
              label="Type"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Type',
                },
              ]}
            >
              <SelectComponent placeholder="Select Type" allowClear>
                <Option key="FIXED" value="FIXED">
                  Fixed
                </Option>
                <Option key="SEAT" value="SEAT">
                  Seat
                </Option>
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="billingCycle"
              label="Billing Cycle"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Billing Cycle',
                },
              ]}
            >
              <SelectComponent placeholder="Select Billing Cycle" allowClear>
                <Option key="MONTH" value="MONTH">
                  Month
                </Option>
                <Option key="YEAR" value="YEAR">
                  Year
                </Option>
              </SelectComponent>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} className="required-row">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="d-flex flex-vertical">
              <span className="bullet-text">Feature list</span>
              <Form.List name="features">
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <div key={key} className="d-flex align-baseline">
                          <Form.Item
                            className="fill-width"
                            {...restField}
                            name={[name, 'feature']}
                            fieldKey={[fieldKey, 'feature']}
                            rules={[required]}
                          >
                            <InputComponent placeholder="Add Feature" />
                          </Form.Item>
                          {index > 1 && (
                            <DeleteOutlined
                              className="delete-icon"
                              onClick={() => remove(name)}
                            />
                          )}
                        </div>
                      ),
                    )}
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => {
                        if (fields?.length < FEATURE_MAX_LIMIT) {
                          add();
                        }
                      }}
                      type="link"
                      className="add-link-btn"
                    >
                      Add
                    </Button>
                  </>
                )}
              </Form.List>
            </div>
          </Col>
        </Row>
        <span className="form-divider-text">OPTIONAL</span>
        <Divider className="form-divider optional-divider" />
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item name="description" label="description">
              <TextAreaComponent
                autoSize={{ minRows: 3, maxRows: 5 }}
                id="add-plan-textarea"
                allowClear={false}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default PlanForm;
