import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { FETCH_TENANT_SUBSCRIPTION } from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const PlansTable = ({ tenantId }) => {
  const {
    state: { pageSize, filterData },
  } = useContext(AppContext);

  const initialPlanFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
    tenantId,
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [plansFilter, setPlansFilter] = useState(initialPlanFilter);
  const [fetchLoading, setFetchLoading] = useState(false);

  const [fetchTenantPlans, { data, loading }] = useLazyQuery(
    FETCH_TENANT_SUBSCRIPTION,
    {
      variables: {
        filter: plansFilter,
        ...(filterData && { where: filterData }),
      },
      onCompleted(res) {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.tenantSubscriptionPlans?.count,
        };
        setPaginationProp(pagination);
        setFetchLoading(false);
      },
      onError() {},
    },
  );

  useEffect(() => {
    fetchTenantPlans({
      variables: {
        filter: plansFilter,
        ...(filterData && { where: filterData }),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'subscriptionPlan',
      key: 'name',
      ellipsis: true,
      className: 'max-width-column',
      width: 200,
      render: (record) => <span>{record?.name}</span>,
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'subscriptionPlan',
      key: 'description',
      ellipsis: true,
      render: (subscriptionPlan) => (
        <span>{subscriptionPlan?.description}</span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      ellipsis: true,
      width: 120,
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      },
    },
  ];

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pageSize;
    setPlansFilter({ ...plansFilter, skip });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchTenantPlans({
      variables: {
        filter: {
          ...plansFilter,
          skip,
        },
      },
    });
  };

  const onSearchChange = async (value) => {
    fetchTenantPlans({
      variables: {
        filter: {
          ...plansFilter,
          skip: value ? 0 : plansFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
      },
    });
    setFetchLoading(true);
  };

  return (
    <div>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            id="search-container-id"
            placeholder="Tenant name or other detail.."
            name="LineOfBusiness"
            getData={onSearchChange}
          />
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={loading || fetchLoading}
            columns={columns}
            data={data?.tenantSubscriptionPlans?.tenantPlans || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};
export default PlansTable;
