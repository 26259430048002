import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import React from 'react';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import withRouter from '../../../components/withRouter';
import useRouter from '../../../hooks/useRouter';
import SubAreaTable from '../components/SubAreaTable';
import CardWrapper from '../components/TenantTabs';
import { GET_LINE_OF_BUSINESS } from '../graphql/Queries';

const SubAreaList = () => {
  const { params: { id, lobId } = {} } = useRouter();

  const { data: { lineOfBusiness } = {} } = useQuery(GET_LINE_OF_BUSINESS, {
    variables: { id: lobId },
    onCompleted: () => {},
    fetchPolicy: 'network-only',
    onError: () => {},
  });
  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton
            customLink={`${ROUTES?.TENANTS}/view/${id}${ROUTES?.LINE_OF_BUSINESSES}`}
          />
          <span className="portal-header">{lineOfBusiness?.label}</span>
        </div>
      </Portal>
      <CardWrapper>
        <SubAreaTable />
      </CardWrapper>
    </Card>
  );
};
export default withRouter(SubAreaList);
