import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_NOTIFICATIONS = gql`
  query notifications(
    $filter: NotificationsFilter
    $where: NotificationWhereInput!
  ) {
    notifications(filter: $filter, where: $where) {
      count
      data {
        id
        templateId
        moduleType
        moduleData
        template {
          key
          title
          content
          createdAt
          updatedAt
        }
        isRead
        createdAt
        updatedAt
      }
    }
  }
`;
