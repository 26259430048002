import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import { forEach } from 'lodash';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formatPhoneNumberWithoutMask } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import withRouter from '../../../components/withRouter';
import useRouter from '../../../hooks/useRouter';
import SponsorForm from '../components/SponsorForm';
import SponsorTabs from '../components/SponsorTabs';
import { ADMIN_UPDATE_SPONSOR } from '../graphql/Mutations';
import { GET_SPONSOR } from '../graphql/Queries';
import '../sponsors.less';

function SponsorEdit() {
  const { params: { id } = {}, navigate } = useRouter();

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { sponsor } = {} } = useQuery(GET_SPONSOR, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    },
  });

  const [adminUpdateSponsor] = useMutation(ADMIN_UPDATE_SPONSOR, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleSubmit = async (formValues, checkBoxArray) => {
    setSubmitLoading(true);

    const selectedIndustries = [];
    forEach(checkBoxArray, (item) => {
      if (item?.checkedChildren?.length > 0) {
        selectedIndustries?.push({
          id: item?.id,
          lineOfBusinessIds: item?.checkedChildren,
        });
      }
    });

    const industryLOBInput = {
      selectedIndustries,
    };

    const sponsorInput = {
      status: formValues?.status,
    };

    try {
      const response = await adminUpdateSponsor({
        variables: {
          data: { sponsorInput, industryLOBInput },
          where: { id: sponsor?.id },
        },
      });
      if (response?.data?.adminUpdateSponsor) {
        setSubmitLoading(false);
        navigate(ROUTES?.SPONSORS);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) return <Navigate to={ROUTES?.SPONSORS} replace />;

  const initialValues = {
    firstName: sponsor?.firstName,
    lastName: sponsor?.lastName,
    phoneNo: formatPhoneNumberWithoutMask(sponsor?.phoneNo)?.slice(-10),
    email: sponsor?.email,
    businessName: sponsor?.businessName,
    subDomain: sponsor?.subDomain,
    status: sponsor?.status,
    addressLine1: sponsor?.location?.addressLine1,
    addressLine2: sponsor?.location?.addressLine2,
    addressLine3: sponsor?.location?.addressLine3,
    city: sponsor?.location?.city,
    country: sponsor?.location?.country,
    zipCode: sponsor?.location?.zipCode,
    state: sponsor?.location?.state,
    industry: sponsor?.industry,
    lineOfBusiness: sponsor?.lineOfBusiness,
  };

  return (
    <Card className="full-height-card card-body-p-24 sponsor-management">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.SPONSORS} />
          {sponsor && (
            <span className="portal-header">
              {`${sponsor?.firstName} ${sponsor?.lastName}`}
            </span>
          )}
        </div>
      </Portal>
      <SponsorTabs>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <SponsorForm
            isEdit
            sponsorData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </SponsorTabs>
    </Card>
  );
}

export default withRouter(SponsorEdit);
