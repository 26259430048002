import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formatPhoneNumberWithoutMask } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import withRouter from '../../../components/withRouter';
import useRouter from '../../../hooks/useRouter';
import UserForm from '../components/UserForm';
import UserTabs from '../components/UserTabs';
import { UPDATE_USER } from '../graphql/Mutations';
import { GET_USER } from '../graphql/Queries';

function UserEdit() {
  const { navigate, params } = useRouter();
  const { id } = params;

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { user } = {} } = useQuery(GET_USER, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    },
  });

  const [updateUserMutate] = useMutation(UPDATE_USER, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const locations = [];

    locations?.push({
      addressLine1: formValues?.addressLine1 || null,
      city: formValues?.city || null,
      state: formValues?.state || null,
      country: formValues?.country || null,
      zipCode: formValues?.zipCode || null,
    });

    const newFormValues = {
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      email: formValues?.email,
      roles: formValues?.roles,
      isActive: formValues?.isActive,
      phoneNo: formatPhoneNumberWithoutMask(formValues?.phoneNo)?.slice(-10),
      locations,
    };

    const variables = { data: newFormValues, where: { id: user?.id } };

    try {
      const response = await updateUserMutate({
        variables: { ...variables },
      });
      if (response?.data?.adminUpdateUser) {
        setSubmitLoading(false);
        navigate(ROUTES?.USERS);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    <Navigate to={ROUTES?.USERS} replace />;
  }

  const initialValues = {
    ...user,
    ...user?.locations[0],
    phoneNo: formatPhoneNumberWithoutMask(user?.phoneNo)?.slice(-10),
    isActive: user?.isActive,
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.USERS} />
          {user && (
            <span className="portal-header">
              {`${user?.firstName} ${user?.lastName}`}
            </span>
          )}
        </div>
      </Portal>
      <UserTabs>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <UserForm
            isEdit
            userData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </UserTabs>
    </Card>
  );
}

export default withRouter(UserEdit);
