import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect } from 'react';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import withRouter from '../../../components/withRouter';
import useRouter from '../../../hooks/useRouter';
import ApiLogDetails from '../component/ApiLogDetails';
import ApiLogTabs from '../component/ApiLogTabs';
import { GET_API_LOG } from '../graphql/Queries';

const ApiLogView = () => {
  const { params: { id } = {} } = useRouter();

  const [getApiLog, { loading, data }] = useLazyQuery(GET_API_LOG, {
    onCompleted: () => {},
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getApiLog({
      variables: {
        where: {
          id,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterRequestUrl = (requestApi) => {
    const queryStringIndex = requestApi?.search(/\?/);
    if (queryStringIndex > 0) {
      return requestApi?.substring(0, queryStringIndex);
    }
    return requestApi;
  };

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={`${ROUTES?.API_LOGS}`} />
          <span className="portal-header">
            {filterRequestUrl(data?.restApiLog?.requestApi)}
          </span>
        </div>
      </Portal>
      <ApiLogTabs>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <div className="api-log-wrapper">
            <ApiLogDetails logData={data?.restApiLog} />
          </div>
        )}
      </ApiLogTabs>
    </Card>
  );
};
export default withRouter(ApiLogView);
