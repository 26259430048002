import { gql } from '@apollo/client';

export const GET_SIGNED_URL = gql`
  mutation generateSignedUrl(
    $action: String!
    $extension: String!
    $contentType: String
    $key: String!
  ) {
    generateSignedUrl(
      action: $action
      data: { extension: $extension, key: $key, contentType: $contentType }
    ) {
      signedRequest
      url
    }
  }
`;

export const NOTIFICATION_READ = gql`
  mutation notificationRead($data: NotificationReadInput!) {
    notificationRead(data: $data) {
      status
    }
  }
`;

export const CLEAR_NOTIFICATION = gql`
  mutation clearNotification($where: clearNotificationInput) {
    clearNotification(where: $where) {
      status
    }
  }
`;
