/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  LOGOUT: '/logout',
  LOGIN: '/login',
  OTP_VERIFICATION: '/verify-otp',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  VERIFY_EMAIL: '/verify-email',
  CHECKLISTS: '/checklists',
  IMPORTS: '/imports',
  NOTIFICATION: '/notifications',
  PROFILE: '/profile',
  SIGNUP: '/signup',
  ADD_USERS: '/users/add',
  EDIT_USERS: '/users/edit/:id',
  VIEW_USERS: '/users/view/:id',
  EDIT_TENANT: '/tenants/edit/:id',
  VIEW_TENANT: '/tenants/view/:id',
  ADD_TENANT: '/tenants/add',
  TENANTS: '/tenants',
  BRANDS: '/retailers',
  LINE_OF_BUSINESSES: '/line-of-businesses',
  SUB_AREAS: '/serviceType',
  PLANS: '/plans',
  API_LOGS: '/api-logs',
  IMPORT_EXPORT_QUEUE: '/import-export-queue',
  INDUSTRIES: '/industries',
  SPONSORS: '/sponsors',
};

/*  Modules */
export const MODULES = {
  USERS_MANAGEMENT: 'User Management',
  USERS: 'Users',
  LINE_OF_BUSINESSES: 'Lines of Business',
  SUB_AREAS: 'Service Types',
  INDUSTRIES: 'Industries',
  IMPORT_EXPORT_QUEUE: 'Import/Export Queue',
  PLANS: 'Plans',
  API_LOGS: 'API Logs',
  DASHBOARD: 'Dashboard',
  PROFILE: 'Profile',
  LOGOUT: 'Logout',
  USERS_PERMISSION: 'Permissions',
  USERS_ROLES: 'Roles',
  CHECKLISTS: 'Checklists',
  IMPORTS: 'Imports',
  NOTIFICATION: 'Notifications',
  TENANTS: 'Tenants',
  SPONSORS: 'Sponsors',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';
export const USER_EMAIL = 'USER_EMAIL';
export const RESEND_OTP_TIME = 'RESEND_OTP_TIME';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};

/* Date and time */
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
};

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const SKIP_RECORD = 20;

export const TABLE_HEIGHT = 241;
export const TABLE_HEIGHT_SEARCH = 279;

export const FEATURE_MAX_LIMIT = 8;
export const ALLOW_IMAGE_TYPES = ['jpeg', 'jpg', 'png', 'svg'];
export const ALLOW_IMPORT_TYPES = ['csv'];

export const WRITE = 'write';

export const importExportOptions = [
  { key: 'SELECTED', label: 'Selected' },
  { key: 'ALL', label: 'All' },
];

export const RESEND_OTP_ACCOUNT_MINUTES = 5;
export const LOCKED_ACCOUNT_MINUTES = 10;
