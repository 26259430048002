import { Card, Col, Divider, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { formatPhoneNumber } from '../../../common/utils';

const SponsorDetails = ({ sponsorData }) => (
  <div className="user-details">
    <span className="form-divider-text">MANDATORY</span>
    <Divider className="form-divider" />
    <Row gutter={20}>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">First Name :</span>
          <span className="field-value">{sponsorData?.firstName || '-'}</span>
        </div>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">Last Name :</span>
          <span className="field-value">{sponsorData?.lastName || '-'}</span>
        </div>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">Phone Number :</span>
          <span className="field-value">
            {formatPhoneNumber(sponsorData?.phoneNo) || '-'}
          </span>
        </div>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">Email :</span>
          <span className="field-value">{sponsorData?.email || '-'}</span>
        </div>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">Business Name :</span>
          <span className="field-value">
            {sponsorData?.businessName || '-'}
          </span>
        </div>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">Sub Domain :</span>
          <span className="field-value">{sponsorData?.subDomain || '-'}</span>
        </div>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">Status *</span>
          <span className="field-value">
            {sponsorData?.pickListStatus?.label || '-'}
          </span>
        </div>
      </Col>
    </Row>
    <span className="form-divider-text">INDUSTRY - LINE OF BUSINESS</span>
    <Divider className="form-divider" />
    <Row className="mx-16">
      {map(sponsorData?.industry, (industry) => (
        <Card
          bordered={false}
          className="industry-view"
          key={industry?.key}
          title={industry?.label}
        >
          {map(sponsorData?.lineOfBusiness, (lob) => {
            if (lob?.industryId === industry?.id) {
              return (
                <div key={lob?.key} className="lob-view">
                  {lob?.label}
                </div>
              );
            }
          })}
        </Card>
      ))}
    </Row>
    <Row gutter={16}>
      <span className="form-divider-text optional-divider">OPTIONAL</span>
      <Divider className="form-divider optional-divider" />
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">AddressLine 1 :</span>
          <span className="field-value">
            {sponsorData?.locations?.[0]?.addressLine1 || '-'}
          </span>
        </div>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">Country :</span>
          <span className="field-value">
            {sponsorData?.locations?.[0]?.country || '-'}
          </span>
        </div>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">State :</span>
          <span className="field-value">
            {sponsorData?.locations?.[0]?.state || '-'}
          </span>
        </div>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">City :</span>
          <span className="field-value">
            {sponsorData?.locations?.[0]?.city || '-'}
          </span>
        </div>
      </Col>
      <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <div className="field-detail">
          <span className="field-label">Postal Code :</span>
          <span className="field-value">
            {sponsorData?.locations?.[0]?.zipCode || '-'}
          </span>
        </div>
      </Col>
    </Row>
  </div>
);
export default SponsorDetails;
