import { gql } from '@apollo/client';

export const CREATE_SPONSOR = gql`
  mutation createSponsor($data: SponsorInputForCreation!) {
    createSponsor(data: $data) {
      status
      message
    }
  }
`;

export const ADMIN_UPDATE_SPONSOR = gql`
  mutation adminUpdateSponsor(
    $data: AdminSponsorUpdateInput
    $where: SponsorWhereInput
  ) {
    adminUpdateSponsor(data: $data, where: $where) {
      status
      message
    }
  }
`;
