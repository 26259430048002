import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import React from 'react';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import './auth.less';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ForgetPassword = () => {
  const { navigate } = useRouter();
  const [forgetPasswordMutating, { loading }] = useMutation(FORGOT_PASSWORD, {
    onError() {}, // Always write this method for error handling in all mutation.
  });

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim()?.toLowerCase(),
      };
      await forgetPasswordMutating({
        variables: formValues,
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <div className="text-center">
            <h2 className="text-left mt-8">Forgot Your Password ?</h2>
            <p className="text-left">
              Don't worry. Recovering the password is easy. Just tell us the
              email.
            </p>
          </div>
          <Spin spinning={false}>
            <Form layout="vertical" onFinish={onFinish} size="large">
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter email" />
              </Form.Item>
              <Form.Item className="mb-8">
                <Button type="primary" loading={loading} htmlType="submit">
                  Reset Password
                </Button>
                <Button
                  className="ml-8"
                  onClick={() => {
                    navigate(ROUTES?.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </div>
    </div>
  );
};

export default ForgetPassword;
