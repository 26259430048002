import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import Portal from '../../../components/Portal';
import IndustryTable from '../components/IndustryTable';
import '../industry.less';

const IndustryList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-padding industry-module">
      <Portal portalId="header-left-content">
        <span className="portal-header">Industries</span>
      </Portal>
      {pageSize && <IndustryTable />}
    </Card>
  );
};

export default IndustryList;
