import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const { RangePicker } = DatePicker;

const RangePickerComponent = (props) => {
  const {
    showTime = false,
    disabledDate = false,
    showToday = false,
    ...rest
  } = props;

  const disabledDateFun = (current) =>
    // Can not select days before today
    current && current < dayjs().startOf('day');

  return (
    <RangePicker
      bordered
      format={showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
      showTime={showTime}
      showToday={showToday}
      disabledDate={disabledDate && disabledDateFun}
      {...rest}
    />
  );
};

export default RangePickerComponent;
