import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import GoBackButton from '../../../../components/GoBackButton';
import Portal from '../../../../components/Portal';
import { GET_INDUSTRY } from '../../graphql/Queries';
import '../../industry.less';
import LineOfBusinessTable from '../components/LineOfBusinessTable';

const LineOfBusinessesTableList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);

  const { industryId } = useParams();

  const { data: { industry } = {} } = useQuery(GET_INDUSTRY, {
    variables: { where: { id: industryId } },
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  return (
    <Card className="full-height-card card-body-padding industry-module">
      <Portal portalId="header-left-content">
        <span className="portal-header">
          <GoBackButton />
          {industry?.label}
        </span>
      </Portal>
      {pageSize && <LineOfBusinessTable />}
    </Card>
  );
};

export default LineOfBusinessesTableList;
