import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation adminLogin($data: adminLoginInput!) {
    adminLogin(data: $data) {
      status
      message
    }
  }
`;

export const SIGNUP = gql`
  mutation emailPasswordSignUp($data: EmailPasswordSignUpData!) {
    emailPasswordSignUp(data: $data) {
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation adminForgotPassword($email: EmailAddress!) {
    adminForgotPassword(email: $email) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: resetPasswordInput!) {
    resetPassword(data: $data) {
      status
      message
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation verifyToken($data: verifyTokenInput!) {
    verifyToken(data: $data) {
      message
      status
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
    updateCurrentUser(data: $data) {
      message
      data {
        id
        email
        profileImage
        name
        firstName
        lastName
      }
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOtp($data: VerifyOtpInput!) {
    verifyOtp(data: $data) {
      authToken
      message
      user {
        id
        firstName
        lastName
        email
        phoneNo
        roles
        uuid
        isActive
        emailVerified
        tenantId
      }
    }
  }
`;

export const RESEND_OTP = gql`
  mutation ResendOtp($email: EmailAddress!) {
    resendOtp(email: $email) {
      message
      status
    }
  }
`;
