import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import withRouter from '../../../components/withRouter';
import useRouter from '../../../hooks/useRouter';
import UserDetails from '../components/UserDetails';
import UserTabs from '../components/UserTabs';
import { GET_USER } from '../graphql/Queries';

const UserView = () => {
  const [loading, setLoading] = useState(true);
  const { navigate, params } = useRouter();
  const { id } = params;
  const { data: { user } = {} } = useQuery(GET_USER, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    },
  });

  return (
    <Card className="full-height-card card-body-p-24">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.USERS} />
          {user && (
            <span className="portal-header">
              {`${user?.firstName} ${user?.lastName}`}
            </span>
          )}
        </div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          type="primary"
          onClick={() => navigate(`${ROUTES?.USERS}/edit/${id}`)}
        >
          Edit
        </Button>
      </Portal>
      <UserTabs>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <UserDetails userData={user} />
        )}
      </UserTabs>
    </Card>
  );
};
export default withRouter(UserView);
